<div class="app-new-unstaking" [ngClass]="httpService.theme">
    <div *ngIf="
            !loading &&
            httpService.user.user_id > 0 &&
            !show_no_stakings
        ">

        <div class="row mt-2" *ngIf="!this.data?.staking_id">
            <cf-dropdown [theme]="httpService.theme" [options]="stakingDropdownOptions" (change)="changeStaking($event)"
                label="Select Staking" [showLogo]="false" [selected]="staking_id" [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <cf-input-currency [theme]="httpService.theme" [value]="amount" label="Available Amount to Unstake"
                [currency]="currency" [currencyLogo]="httpService.getCurrencyUrl(currency)" type="number"
                (valueChanged)="amountChanged($event)" [precision]="6">
            </cf-input-currency>
        </div>

        <div class="row mt-2">
            <div class="new-unstaking-summary">
                <div class="summary-item">
                    <span class="summary-heading">Staking duration</span>
                    <span class="summary-value"
                        *ngIf="stakings[current_staking_index] && stakings[current_staking_index].staking_duration > 0">
                        {{ stakings[current_staking_index].staking_duration | number: '0.0-0' }} days ({{
                        stakings[current_staking_index].staking_duration / 30 | number: '0.0-0' }} months)
                    </span>
                    <span class="summary-value"
                        *ngIf="stakings[current_staking_index] && stakings[current_staking_index].staking_duration === 0">
                        Flexible
                    </span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Already unstaked</span>
                    <span class="summary-value">{{ unstaked | number: '0.0-6' }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Unstaking fees</span>
                    <span class="summary-value">0
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Receiving amount</span>
                    <span class="summary-value">{{ amount | number: '0.0-6' }}
                        {{ currency | uppercase }}</span>
                </div>
            </div>
        </div>

        <div class="actions">
            <div *ngIf="!btn_loading">
                <!-- <cf-button [theme]="httpService.theme" [label]="confirm_btn_label" type="solid"
                    (clicked)="unstakeCred()"></cf-button> -->

                <div *ngIf="differentBlockchain">
                    <cf-button label="Switch Blockchain" type="solid"
                        (clicked)="openChangeBlockchainDialog()"></cf-button>
                </div>

                <div class="action" *ngIf="!differentBlockchain && walletService.wallet_address !== ''">
                    <cf-button label="Confirm" type="solid" (clicked)="unstakeCred()" *ngIf="showConfirmButton"
                        [loading]="btn_loading"></cf-button>

                    <cf-button label="Switch Network" type="solid" (clicked)="openChangeChainDialog()"
                        *ngIf="differentNetwork"></cf-button>

                </div>
                <div class="action" *ngIf="walletService.wallet_address === ''">
                    <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
                </div>

            </div>
            <div *ngIf="btn_loading">
                <cf-loader [theme]="httpService.theme"></cf-loader>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader [theme]="httpService.theme"></cf-loader>
    </div>
    <div *ngIf="show_no_stakings" class="no-data">
        <div>No stakings</div>
    </div>
</div>