import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit 
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IUser from '../../interfaces/IUser';
import User from '../../models/User';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import ICredPurchase from '../../interfaces/ICredPurchase';
import INode from '../../interfaces/INode';
import ILineConfig from '../charts/line-chart/ILineConfig';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-my-nodes',
    templateUrl: './my-nodes.component.html',
    styleUrls: [ './my-nodes.component.scss' ]
})
export class MyNodesComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    currentTime: number = new Date().getTime();
    nodes: Array<INode> = [];

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    chartLineConfig: Array<ILineConfig> = [];

    rate_change_text: string = '';
    rate_change_text_class: string = 'default';

    statusOptions: Array<IDropdown> = [
        {
            label: 'All',
            value: '',
            optionEnabled: true
        },
        {
            label: 'Pending',
            value: 'pending',
            optionEnabled: true
        },
        {
            label: 'Processing',
            value: 'processing',
            optionEnabled: true
        },
        {
            label: 'Completed',
            value: 'completed',
            optionEnabled: true
        },
        {
            label: 'Failed',
            value: 'failed',
            optionEnabled: true
        }
    ];
    selected_status: string = 'all';

    constructor(
        public httpService: HttpService,
        private genesisService: GenesisService,
        private cfAlertService: CfAlertService,
        private router: Router
    ) {}

    ngOnInit(): void {
        console.log('my-nodes.component.ts ngOnInit()');

        this.getNodes();

        this.httpService.setMetaData('My Node', 'Node purchased by the user');
    }

    getNodes() {
        this.loading = true;

        const body: any = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: this.selected_status
        };

        if (this.selected_status === 'all')
            delete body.status;

        this.genesisService.getNodes(body).subscribe(
            (res: any) => {
                this.nodes = res.data;

                console.log('this.nodes', this.nodes);

                this.total_count = res.total_count;

                if (this.total_count > 0) {
                    this.nodes.forEach(node => {
                        node.current_node_value = node.cred_receivable * this.genesisService.cred_rate;
    
                        node.percentage_change = parseFloat((((node.current_node_value - node.cred_receivable_usd) / node.cred_receivable_usd) * 100).toFixed(2));
    
                        const difference = parseFloat((node.current_node_value - node.cred_receivable_usd).toFixed(2));

                        if (node.percentage_change > 0)
                            node.current_node_value_change_percentage = `+ $${difference}  (+${node.percentage_change}%)`;
    
                        if (node.percentage_change < 0)
                            node.current_node_value_change_percentage = `- $${difference * -1}  (${node.percentage_change}%)`;

                        this.chartLineConfig = [{
                            name: '',
                            data: node.rate_chart,
                            lineColor: '#cf0'
                        }];

                        node.rate_chart = this.chartLineConfig[0];
                    });
                }

                this.loading = false;
            }, (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getNodes();
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.page = 0;
        this.getNodes();
    }

    redirectToNodeDetail(node: ICredPurchase) {
        this.router.navigate([ '/my-nodes', node.node_id ]);
    }

    getDummyChartData() {
        const data = [];

        let current_time = new Date().setSeconds(0, 0);

        for (let i = 0; i < 30; i++) {
            data.push({
                x: current_time,
                y: 0
            });

            current_time = new Date(
                current_time - 24 * 60 * 60 * 1000
            ).setSeconds(0, 0);
        }

        return data;
    }

    redirectToNodeDetails(node_id: string) {
        this.router.navigate([ '/my-nodes', node_id ]);
    }
}
