import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import {
    ActivatedRoute, Router
} from '@angular/router';
import { getWindow } from 'ssr-window';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

@Component({
    selector: 'app-genesis-members',
    templateUrl: './members.component.html',
    styleUrl: './members.component.scss'
})
export class GenesisMembersComponent implements OnInit {
    loading: boolean = false;
    members: Array<any> = [];
    totalChildrenDataCount: number = 0;

    page: number = 0;
    limit: number = 15;
    level: number = 1;
    window: Window;
    referred_user_count: any;
    invitation_link: any;

    statusOptions: Array<IDropdown> = [
        {
            label: 'Oracle 1',
            value: '1',
            optionEnabled: true
        },
        {
            label: 'Oracle 2',
            value: '2',
            optionEnabled: true
        },
        {
            label: 'Oracle 3',
            value: '3',
            optionEnabled: true
        },
        {
            label: 'Oracle 4',
            value: '4',
            optionEnabled: true
        },
        {
            label: 'Oracle 5',
            value: '5',
            optionEnabled: true
        },
        {
            label: 'Oracle 6',
            value: '6',
            optionEnabled: true
        },
        {
            label: 'Oracle 7',
            value: '7',
            optionEnabled: true
        },
        {
            label: 'Oracle 8',
            value: '8',
            optionEnabled: true
        },
        {
            label: 'Oracle 9',
            value: '9',
            optionEnabled: true
        }
    ];
    selected_status: string = '';

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.getMembers();
        this.getUserTasks();
        this.invitation_link = `${this.window.location.origin}/invite/${this.httpService.user.referral_code}`;
    }

    getMembers() {
        this.loading = true;
        const data = {
            level: this.level,
            page: this.page,
            limit: this.limit
        };

        this.httpService.getMembersGenesis(data).subscribe(
            (res) => {
                this.members = res.data.children_data;
                this.totalChildrenDataCount = res.data.totalChildrenDataCount;
                this.loading = false;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    pageChanged(event: any) {
        this.page = event;
        this.getMembers();
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.level = event.value;
        this.page = 0;
        this.getMembers();
    }

    getUserTasks() {
        this.httpService.getUserTasks().subscribe((res: any) => {
            this.referred_user_count = res.data.referred_user_count;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }
}
