import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, EventEmitter, Inject, Input, NgZone, OnChanges, OnInit,
    Output,
    PLATFORM_ID,
    SimpleChanges
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';

import { Router } from '@angular/router';

import { WalletService } from '../../services/wallet.service';
import {Transaction} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import Node from '../../models/Node';
import INode from '../../interfaces/INode';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import { SolflareService } from '../../services/solflare.service';
import {
    DecimalPipe, isPlatformBrowser
} from '@angular/common';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { SaleAgreementComponent } from '../sale-agreement/sale-agreement.component';
import { getWindow } from 'ssr-window';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import { ChangeBlockchainDialogComponent } from '../change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from '../change-chain-dialog/change-chain-dialog.component';
import { MetamaskService } from '../metamask.service';
import ETHEREUM_CHAINS from '../../constants/EthereumChains';
import getSolanaRawTransaction from '../../helpers/getSolanaRawTransaction';
import getSolanaConnection from '../../helpers/getSolanaConnection';
import { KycService } from '../../services/kyc.service';
import { InviteCodeModalComponent } from '../invite-code-modal/invite-code-modal.component';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-new-node',
    templateUrl: './new-node.component.html',
    styleUrls: [ './new-node.component.scss' ]
})

export class NewNodeComponent implements OnInit, OnChanges {
    @Input() plan_id: string = '';
    window = getWindow();
    isBrowser: boolean = false;
    platformId: object = {};

    currentMintAddressMap: any = {};
    payment_currency: string = '';
    payment_amount: number = 0;
    receivable_amount: number = 0;
    original_payment_amount: number = 0;

    node_id: string = '';
    balance: number = 0;
    sol_balance: number = 0;
    showModal: boolean = false;

    loading: boolean = true;
    new_node_loading: boolean = false;
    transaction: any;

    show_no_nodes: boolean = false;

    payment_currencies: Array<IDropdown> = [];

    node: INode = new Node();
    disabled: boolean = true;

    showConfirmButton: boolean = false;
    showAllowanceButton: boolean = false;
    differentNetwork: boolean = false;
    differentBlockchain: boolean = false;
    approval_txid: string = '';

    currencyOptions: Array<IDropdown> = [
        {
            label: 'USDC',
            value: 'usdc',
            logo: this.httpService.getCurrencyUrl('usdc'),
            optionEnabled: true
        }
    ];

    selected_amount: string = '';
    discount: string = '';
    confirm_btn_label: string = 'Confirm';

    disclaimer_accepted: boolean = false;
    @Output() changePlanId = new EventEmitter();
    nodeSelectDropdown: Array<IDropdown> = [];
    @Input() nodes: Array<INode> = [];

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private genesisService: GenesisService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        public walletService: WalletService,
        private decimalPipe: DecimalPipe,
        private kycService: KycService,
        public dialog: MatDialog,
        public blockchainDialogRef: MatDialogRef<ChangeBlockchainDialogComponent>,
        public networkDialogRef: MatDialogRef<ChangeChainDialogComponent>,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>,
        private ngZone: NgZone,
        private metamaskService: MetamaskService,
        public dialogRef: MatDialogRef<InviteCodeModalComponent>,
        @Inject(PLATFORM_ID) platformId: object
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['nodes'] && changes['nodes'].currentValue) 
            this.nodesChanged(changes['nodes'].currentValue);
    }

    nodesChanged(nodes: Array<INode>) {
        if (nodes.length === 0)
            return ;

        this.nodes = nodes;
        this.node = this.nodes[0];

        this.plan_id = this.node.plan_id;

        this.nodeSelectDropdown = nodes.map((node: INode) => {
            return {
                label: node.name,
                value: node.plan_id,
                logo: this.httpService.getCurrencyUrl('vCRED'),
                optionEnabled: true
            };
        });

        this.setAmountOptions();

        this.payment_currency = this.node.payment_currency;

        this.original_payment_amount = this.node.cred_receivable * this.node.cred_rate_payment_currency;
        this.payment_amount = this.original_payment_amount - (this.original_payment_amount * (this.node.discount_rate / 100));

        this.payment_currencies.push({
            label: this.payment_currency.toUpperCase(),
            value: this.payment_currency,
            logo: this.httpService.getCurrencyUrl(
                this.payment_currency
            ),
            optionEnabled: true
        });

        this.currentMintAddressMap[this.payment_currency] = this.node.payment_currency_mint_address;

        this.setConfirmBtnLabel();

        if (this.walletService.isWalletConnected()) {
            this.getCurrencyBalance(this.node.blockchain, this.node.chain, this.node.payment_currency_mint_address);
            this.getGasFeeBalance(this.node.blockchain, this.node.chain);

            if (this.node.blockchain === 'solana') {
                this.showConfirmButton = true;
                this.showAllowanceButton = false;
            }

            else {
                this.showConfirmButton = false;
                this.showAllowanceButton = true;
            }
        }
        
        this.getPlanDetails();
        
        this.changePlanId.emit(this.plan_id);
        
        this.loading = false;
    }

    ngOnInit(): void {
        console.log('new-node.component.ts ngOnInit()');
    }

    showLoader() {
        this.show_no_nodes = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    setAmountOptions() {
        this.selected_amount = this.node.cred_receivable.toString();
        this.receivable_amount = parseFloat(this.selected_amount);

        this.discount = `${this.node.discount_rate}%`;
    }

    getCurrencyBalance(blockchain: string, chain: string, mint_address: string) {
        if (!this.isBrowser)
            return;

        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address, mint_address).subscribe((res: any) => {
            this.balance = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getGasFeeBalance(blockchain: string, chain: string) {
        this.httpService.getCurrencyBalance(blockchain, chain, this.walletService.wallet_address).subscribe((res: any) => {
            this.sol_balance = res.data;

            if (this.node.blockchain === 'solana') {
                this.showConfirmButton = true;
                this.showAllowanceButton = false;
            }

            else {
                this.showConfirmButton = false;
                this.showAllowanceButton = true;
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    createNode() {
        if (
            isNaN(this.payment_amount) || this.payment_amount === null || this.payment_amount === undefined || this.payment_amount === 0
        ) {
            this.cfAlertService.showMessage('Please enter valid amount', true);

            return;
        }

        if (this.balance < this.payment_amount) {
            this.cfAlertService.showMessage('Insufficient balance', true);

            return;
        }

        if (this.sol_balance <= 0) {
            this.cfAlertService.showMessage('Insufficient SOL balance', true);

            return;
        }

        // if (!this.disclaimer_accepted) {
        //     this.openSaleAggreement();

        //     return;
        // }

        this.new_node_loading = true;

        const body = {
            plan_id: this.plan_id,
            wallet_address: this.walletService.wallet_address
        };

        this.genesisService.createNode(body).subscribe(
            (res: any) => {
                this.node_id = res.data.node_id;

                const transaction = Transaction.from(
                    Buffer.from(res.data.transaction, 'base64')
                );

                this.sendTransaction(transaction);
            },
            (err: HttpErrorResponse) => {
                console.error('createNode error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.new_node_loading = false;
            }
        );
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(
                transaction
            );

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    async sendTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature) return;

            transaction = res;

            const connection = getSolanaConnection();

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(
                transaction.serialize({
                    requireAllSignatures: true,
                    verifySignatures: true
                })
            );

            console.log('sendTransaction : txn signature => ', txnSignature);
        }
        catch (err: any) {
            console.error('sendTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split(
                'custom program error:'
            );

            if (split_items.length > 0) {
                error_code = parseInt(
                    split_items[split_items.length - 1].trim(),
                    16
                );
            }

            console.log('sendTransaction : error_code => ', error_code);
        }
        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    updateSignature(signature: string, error_code: number) {
        const body = {
            node_id: this.node_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.genesisService.updateNode(body).subscribe(
            (res: any) => {
                this.new_node_loading = false;

                this.cfAlertService.showMessage('Purchase succesful');

                this.router.navigateByUrl('nodes/my-nodes');
            },
            (err: HttpErrorResponse) => {
                console.error('updateNode error');
                console.error(err);

                this.cfAlertService.showError(err);

                this.new_node_loading = false;
            }
        );
    }

    // connectWallet() {
    //     this.router.navigate(['/connect-wallet'], {
    //         queryParams: {
    //             returnUrl: this.router.url
    //         }
    //     });
    // }

    currencyChanged(currency: string) {
        this.payment_currency = currency;

        this.getCurrencyBalance(
            this.node.blockchain,
            this.node.chain,
            this.currentMintAddressMap[this.payment_currency]
        );
    }

    setConfirmBtnLabel() {
        this.confirm_btn_label = 'Pay ' + this.decimalPipe.transform(this.payment_amount, '0.0-2') + ' ' + this.payment_currency.toUpperCase();
    }

    openSaleAggreement() {
        const dialogRef = this.dialog.open(SaleAgreementComponent, {
            data: {
                plan_id: this.plan_id,
                cred_rate_payment_currency: this.node.cred_rate_payment_currency,
                payment_currency: this.payment_currency,
                receivable_amount: this.receivable_amount
            },
            panelClass: 'custom-dialog-container',
            disableClose: true,
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '90%',
            width: '100%'
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('result', result);

            if (result.status === 'accept') {
                this.disclaimer_accepted = true;

                this.createNode();
            }
        });
    }

    redirectToKyc() {

    }

    generateKycUrl() {
        this.loading = true;
        this.kycService.generateKycUrl(this.httpService.user).subscribe(
            (res: any) => {
                this.loading = false;

                this.window.location.href = res.data;
            },
            (err: HttpErrorResponse) => {
                console.error(err);

                this.loading = false;

                this.cfAlertService.showError(err);
            }
        );
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });

        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            this.ngZone.run(async () => {
                console.log('connectWallet afterClosed', status);

                if (this.walletService.isWalletConnected()) {
                    if (this.node.blockchain !== this.walletService.blockchain) {
                        console.log(this.node.blockchain, this.walletService.blockchain);
                        this.openChangeBlockchainDialog();
                    }

                    else
                        await this.openChangeChainDialog();

                    if (this.node.blockchain === 'solana') {
                        this.showConfirmButton = true;
                        this.showAllowanceButton = false;
                    }

                    else {
                        this.showConfirmButton = false;
                        this.showAllowanceButton = true;
                    }

                    console.log('showConfirmButton', this.showConfirmButton);
                    console.log('showAllowanceButton', this.showAllowanceButton);
                    console.log('differentBlockchain', this.differentBlockchain);
                    console.log('differentNetwork', this.differentNetwork);
                    console.log('walletService.current_provider', this.walletService.current_provider);

                    const mint_address = this.currentMintAddressMap[this.payment_currency];

                    this.getCurrencyBalance(this.node.blockchain, this.node.chain, this.node.payment_currency_mint_address);
                    this.getGasFeeBalance(this.node.blockchain, this.node.chain);
                }

                else
                    console.log('wallet not connected');
            });
        });
    }

    openChangeBlockchainDialog() {
        console.log('openChangeBlockchainDialog');

        this.differentBlockchain = true;

        this.blockchainDialogRef = this.dialog.open(ChangeBlockchainDialogComponent, {
            width: '500px',
            data: {
                expected_blockchain: this.node.blockchain,
                current_blockchain: this.walletService.blockchain
            }
        });

        this.blockchainDialogRef.afterClosed().subscribe(result => {
            if (result)
                console.log('result', result);
        });
    }

    async openChangeChainDialog() {
        console.log('openChangeChainDialog', this.walletService.blockchain);

        if (this.walletService.blockchain === 'solana') {
            if (this.node.chain === 'solana')
                return true;
        }

        const chainId = await this.metamaskService.getChainId();

        this.cfAlertService.showMessage(`Chain id: ${chainId}`, false);

        console.log('chainId', chainId);

        const chainIdDecimal = parseInt(chainId, 16);

        console.log('chainIdDecimal', chainIdDecimal);

        const currentChainInfo = ETHEREUM_CHAINS[chainIdDecimal];

        console.log('currentChainInfo', currentChainInfo);

        console.log(this.node.chain_id, currentChainInfo.chainId);

        if (this.node.chain_id === currentChainInfo.chainId)
            return true;

        this.differentNetwork = true;

        this.networkDialogRef = this.dialog.open(ChangeChainDialogComponent, {
            width: '500px',
            data: {
                expected_chain: this.node.chain_id,
                current_chain: currentChainInfo.chainId
            }
        });

        this.networkDialogRef.afterClosed().subscribe(result => {
            if (result)
                console.log('result', result);
        });

        return false;
    }

    async sendSolanaTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSolanaSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;

            transaction = res;

            txnSignature = await getSolanaRawTransaction(transaction);

            console.log('sendSolanaTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendSolanaTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split('custom program error:');

            if (split_items.length > 0)
                error_code = parseInt(split_items[split_items.length - 1].trim(), 16);

            console.log('sendSolanaTransaction : error_code => ', error_code);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    async sendEthereumAllowanceTransaction(abi: string, contract_address: string, gas: number) {
        try {
            this.approval_txid = await this.getEthereumSignature(abi, contract_address, gas);

            console.log('allowance_signature', this.approval_txid);

            this.cfAlertService.showMessage('Allowance succesful');

            this.createNode();
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            this.cfAlertService.showMessage(err.message, true);
        }
    }

    async sendEthereumTransaction(lending_abi: string, lending_gas: number) {
        let error_code = -1;
        let txnSignature = '';

        try {
            txnSignature = await this.getEthereumSignature(lending_abi, this.node.program_id, lending_gas);

            console.log('sendEthereumTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendEthereumTransaction catch');
            console.error(err);

            error_code = err.code;

            this.cfAlertService.showMessage(err.message, true);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    async getEthereumSignature(abi: string, contract_address: string, gas: number) {
        if (this.walletService.current_provider === 'metamask') {
            const response = await this.metamaskService.signTransaction(abi, contract_address, gas);

            console.log('signTransaction', response);

            return response;
        }

        return '';
    }

    async getSolanaSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    updateInvite() {
        const dialogRef = this.dialog.open(InviteCodeModalComponent, {
            data: {
                showModal: true
            },
            disableClose: true
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log('result', result);

            if (result.status === 'accept') {
               
            }
        });
    }

    closeModal() {
        this.showModal = false;
    }

    getPlanDetails() {
        this.loading = true;

        this.genesisService.getPlanDetails(this.plan_id).subscribe((res) => {
            this.node = res.data;

            this.httpService.setMetaData(
                `${this.decimalPipe.transform(this.node.cred_receivable, '0.0-0')} vCRED`,
                this.node.description
            );

            this.setAmountOptions();

            this.payment_currency = this.node.payment_currency;

            this.original_payment_amount = this.node.cred_receivable * this.node.cred_rate_payment_currency;
            this.payment_amount = this.original_payment_amount - (this.original_payment_amount * (this.node.discount_rate / 100));

            this.payment_currencies.push({
                label: this.payment_currency.toUpperCase(),
                value: this.payment_currency,
                logo: this.httpService.getCurrencyUrl(this.payment_currency),
                optionEnabled: true
            });

            this.currentMintAddressMap[this.payment_currency] = this.node.payment_currency_mint_address;

            this.setConfirmBtnLabel();

            this.loading = false;
        },
        (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        }
        );
    }

    changePlanIdDropdown(option: IDropdown) {
        this.plan_id = option.value;

        this.getPlanDetails();
        
        this.changePlanId.emit(this.plan_id);
    }
}