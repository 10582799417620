<div class="app-my-node-details container-fluid" [ngClass]="httpService.theme">
    <div class="main-container">
        <div class="section-1">
            <div class="card-locked">
                <div class="card-content">
                    <span class="card-title">Unlocked</span>
                    <span class="card-value unlocked-value">{{node.cred_unlocked | number:'0.0-4'}} vCRED</span>
                </div>
                <span class="divider">/</span>
                <div class="card-content">
                    <span class="card-title">Locked</span>
                    <span class="card-value">{{node.cred_locked | number:'0.0-4'}} vCRED</span>
                </div>
            </div>
            <div class="card-staked">
                <div class="card-content">
                    <span class="card-title">Daily unlock</span>
                    <span class="card-value daily-unlock-value">{{daily_unlock}} vCRED</span>
                </div>
                <div class="card-content">
                    <span class="card-title">Unstaked</span>
                    <span class="card-value unStaked-value">{{node.staking_info.unstaked}} vCRED</span>
                </div>
            </div>
        </div>

        <div class="section-2">
            <div class="card-content">
                <span class="card-title">Staking Rewards Earned</span>
                <span class="card-value staking-rewards-value">{{node.staking_reward_info.amount | number: '0.0-6'}}
                    vCRED</span>
            </div>

            <div class="card-content">
                <span class="card-title">Unclaimed Rewards</span>
                <span class="card-value unclaimed-rewards-value">{{node.staking_reward_info.unclaimed_amount | number:
                    '0.0-6'}}
                    vCRED</span>
            </div>


        </div>

        <div class="section-3">
            <div class="card-content">
                <span class="card-title">Paid amount : </span>
                <span class="card-value">{{node.payment_amount | number:'0.0-6'}} {{node.payment_currency |
                    uppercase}}</span>
            </div>
            <div class="card-content">
                <span class="card-title">Discount applied : </span>
                <span class="card-value">{{node.discount_rate}}%</span>
            </div>
            <div class="card-content">
                <span class="card-title">Blockchain : </span>
                <span class="card-value">{{node.blockchain | titlecase }}</span>
            </div>
            <div class="card-content">
                <span class="card-title">Chain : </span>
                <span class="card-value">{{node.chain | titlecase}}</span>
            </div>
            <div class="card-content">
                <span class="card-title">Blockchain Tx ID : </span>
                <div class="redirection">
                    <span class="card-value ellipses">{{node.blockchain_txid}}</span>
                    <i class="fa-solid fa-up-right-from-square redirection-icon"
                        (click)=" httpService.redirectionTx(node.blockchain, node.chain, node.network, node.blockchain_txid)"></i>
                </div>
            </div>
            <div class="card-content">
                <span class="card-title">Node type : </span>
                <span class="card-value">{{node.node_type | titlecase}}</span>
            </div>
            <div class="card-content">
                <span class="card-title">Node ID : </span>
                <span class="card-value">{{node.node_id}}</span>
            </div>
            <div class="card-content">
                <span class="card-title">Start date : </span>
                <span class="card-value">{{node.created | date:'mediumDate'}}</span>
            </div>
            <div class="card-content">
                <span class="card-title">Unlock days : </span>
                <span class="card-value">{{node.staking_info.staking_duration}} days</span>
            </div>
        </div>

        <div class="section-4">
            <div class="graph-card">
                <span class="graph-heading">vCRED Rate</span>
                <!-- <app-line-chart [data]="node.rate_chart" [name]="'vCRED Rate'" [date_format]="'mediumDate'"
                    [precision]="6" [currency]="'vCRED'" [height]="400"></app-line-chart> -->

                <app-line-chart [lineConfig]="chartLineConfig" title="" [currency]="'vCRED'"></app-line-chart>

            </div>
            <div class="withdraw-card">
                <app-purchase-node-widget [data]="node"></app-purchase-node-widget>
            </div>
        </div>

        <div class="section-5">
            <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
                (changed)="changeTab($event)"></cf-tabs>
        </div>

        <div class="tabs">
            <!-- <app-node-rewards *ngIf="currentTab === 'node-rewards'"></app-node-rewards> -->
            <app-my-unstakings [data]="node" *ngIf="currentTab === 'my-unstakings'"></app-my-unstakings>
            <app-node-unlock-schedule *ngIf="currentTab === 'vesting-schedule'"
                [node_id]="node_id"></app-node-unlock-schedule>
            <app-node-loyalty-schedule *ngIf="currentTab === 'loyalty-schedule'"
                [node_id]="node_id"></app-node-loyalty-schedule>
        </div>
    </div>
</div>