<div class="app-new-node dark">
    <div *ngIf="!loading && !show_no_nodes">
        <div class="row mb-2">
            <cf-dropdown [theme]="httpService.theme" [options]="nodeSelectDropdown" [selected]="plan_id"
                label="Select node" [showLogo]="true" (change)="changePlanIdDropdown($event)">
            </cf-dropdown>
        </div>

        <div class="row">
            <div class="new-node-summary">
                <div class="summary-item">
                    <span class="summary-heading">vCRED Rate</span>
                    <span class="summary-value">{{ node.cred_rate_payment_currency | number: '0.0-6' }}
                        {{ payment_currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">vCRED receivable</span>
                    <span class="summary-value" style="color: #cf0">{{ receivable_amount | number: '0.0-6' }}
                        vCRED</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Daily unlocking</span>
                    <span class="summary-value">{{ (receivable_amount / node.staking_duration) | number: '0.0-6' }}
                        vCRED</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Vesting duration</span>
                    <span class="summary-value">{{ node.staking_duration }} days ({{ node.staking_duration / 30 }}
                        months)</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Loyalty duration</span>
                    <span class="summary-value">{{ node.loyalty_duration }} days ({{ node.loyalty_duration / 30 }}
                        months)</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Card based unlock</span>
                    <span class="summary-value">{{ node.card_based_unlock_percentage }} %</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Reward weight</span>
                    <span class="summary-value">{{ node.reward_weight_percentage }} %</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Implied FDV</span>
                    <span class="summary-value">$ {{ node.implied_fdv }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Fees</span>
                    <span class="summary-value">0 {{payment_currency | uppercase}}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Discount</span>
                    <span class="summary-value">{{ node.discount_rate }} %</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading d-grid align-items-center">You pay</span>
                    <div class="summary-value d-grid align-items-center">
                        <span class="original" style="text-decoration: line-through;">{{ original_payment_amount |
                            number : '0.0-2' }} {{node.payment_currency | uppercase}}</span>
                        <span class="discounted" style="color: #cf0">{{ payment_amount | number : '0.0-2' }}
                            {{node.payment_currency |
                            uppercase}}</span>
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <div class="actions">
            <!-- <cf-button [theme]="httpService.theme" [label]="confirm_btn_label" type="solid" (clicked)="createNode()"
                [loading]="new_node_loading"></cf-button>
            <div class="equivalent-amount">
                Available:
                {{
                balance
                | number : httpService.getPrecision(payment_currency)
                }}
                {{ payment_currency | uppercase }}
            </div> -->

            <div *ngIf="differentBlockchain">
                <cf-button label="Switch Blockchain" type="solid" (clicked)="openChangeBlockchainDialog()"></cf-button>
            </div>

            <div class="action"
                *ngIf="!differentBlockchain && httpService.user?.referred_by && walletService.wallet_address !== ''">
                <cf-button label="Confirm" type="solid" (clicked)="createNode()" *ngIf="showConfirmButton"
                    [loading]="new_node_loading"></cf-button>

                <cf-button label="Switch Network" type="solid" (clicked)="openChangeChainDialog()"
                    *ngIf="differentNetwork"></cf-button>

                <!-- <cf-button label="Approve" type="solid" (clicked)="approveAllowance()"
                     *ngIf="!differentNetwork && showAllowanceButton" [loading]="new_node_loading"></cf-button> -->
            </div>
            <div class="action" *ngIf="httpService.user?.referred_by && walletService.wallet_address === ''">
                <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
            </div>
            <div class="action" *ngIf="!httpService.user?.referred_by">
                <cf-button label="Invite code" type="solid" (clicked)="updateInvite()"></cf-button>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader [theme]="httpService.theme"></cf-loader>
    </div>
    <div *ngIf="show_no_nodes" class="no-data">
        <div>No nodes</div>
    </div>
</div>