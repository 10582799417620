<div class="app-node-loyalty-schedule" [ngClass]="httpService.theme">
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div class="row" *ngIf="!loading">
        <div class="col-md-12 box">
            <table>
                <thead>
                    <th>Date</th>
                    <th>Days</th>
                    <th>Daily</th>
                    <th>Monthly</th>
                    <th>Cumulative</th>
                </thead>
                <tbody>
                    <tr *ngFor="let item of loyalty_schedule">
                        <td>{{ item.time | date:'MMMM yyyy' }}</td>
                        <td>
                            {{ item.total_days }} days
                        </td>
                        <td>
                            {{ item.cred_receivable_per_day | number:'0.0-6'}} vCRED
                        </td>
                        <td>
                            {{ item.monthly_amount | number:'0.0-6'}} vCRED
                        </td>
                        <td>
                            {{ item.cumulative_amount | number:'0.0-6'}} vCRED
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>