/* eslint-disable array-bracket-spacing */
import {
    Component, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { getWindow } from 'ssr-window';
import { DiscordService } from '../../services/discord.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import { KycService } from '../../services/kyc.service';
import { TwitterService } from '../../services/twitter.service';
import { OnboardingService } from '../onboarding.service';
import { WalletService } from '../../services/wallet.service';
import { ConnectWalletComponent } from '../dialogs/connect-wallet/connect-wallet.component';
import {
    MatDialog, MatDialogRef
} from '@angular/material/dialog';
import { RecaptchaService } from '../recaptcha.service';

@Component({
    selector: 'app-spaceship',
    templateUrl: './spaceship.component.html',
    styleUrls: ['./spaceship.component.scss']
})
export class SpaceshipComponent implements OnInit {
    width: any;
    constructor(public httpService: HttpService,
        private discordService: DiscordService,
        private cfAlertService: CfAlertService,
        private router: Router,
        private recaptchaService: RecaptchaService,
        private kycService: KycService,
        private twitterService: TwitterService,
        private onboardingService: OnboardingService,
        public walletService: WalletService,
        public dialog: MatDialog,
        private connectWalletDialogRef: MatDialogRef<ConnectWalletComponent>
    ) {
        this.window = getWindow();
        this.width = this.window.outerWidth;
    }

    multiplier_tasks: Array<any> = [];
    non_multiplier_tasks: Array<any> = [];
    total_task_count: any;
    completed_task_count: any;
    pending_task_count: any;
    total_user_moon = 0;
    referred_user_count: any;
    invitation_link: any;
    use_manual_recaptcha: boolean = false;
    questions: Array<any> = [];
    max: number = 5;
    current: number = 2;
    window: Window;

    public discord: any = {
        loading: false,
        loginButton: true,
        serverJoined: false,
        checkGroupButton: false,
        checkGroupJoinedIntervalId: null,
        accountConnected: false,
        groupCheckAttempt: 0
    };

    public twitter: any = {
        loading: false,
        accountConnected: false
    };

    kyc_loading: boolean = false;
    twitterJoined: boolean = false;
    discordJoined: boolean = false;
    telegramJoined: boolean = false;
    discord_join_loading: boolean = false;
    twitter_join_loading: boolean = false;
    share_twitter_join_loading: boolean = false;
    telegram_join_loading: boolean = false;

    ngOnInit(): void {
        this.getUserTasks();
        this.getSpaceshipFaqQuestions();

        this.invitation_link = `${this.window.location.origin}/invite/${this.httpService.user.referral_code}`;

        this.attachSocketEvents();
    }

    attachSocketEvents() {
        this.httpService.socket.on('moons_updated', (data: any) => {
            this.total_user_moon += data.moons;
            this.getUserTasks();
            this.httpService.getCurrentUser();
        });
    }

    get progress(): number {
        return (this.completed_task_count / this.total_task_count) * 100;
    }

    openQues(index: any) {
        this.questions[index].open = true;
    }

    closeQues(index: any) {
        this.questions[index].open = false;
    }

    redirectUrl(url: any) {
        this.window.open(url, '_blank');
    }

    login() {
        this.httpService.redirectUrl(this.constructor.name, '/login', {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    loginWith(id: any, redirect_url: string) {
        switch (id) {
            case 'discord':
                this.connectDiscord(redirect_url);
                break;

            case 'kyc':
                this.generateKycUrl();
                break;

            case 'twitter':
                this.connectTwitter(redirect_url);
                break;

            case 'share_on_twitter':
                this.shareOnTwitter();
                break;

            case 'telegram':
                this.connectTelegram(redirect_url);
                break;

            case 'lend_payfi':
                this.lendNow(redirect_url);
                break;

            case 'oracle_license':
                this.oracleLicense(redirect_url);
                break;

            case 'lend_pusd':
                this.router.navigate(['/cash-loan/lend']);
                break;

            case 'borrow':
                this.router.navigate(['/cash-loan/borrow']);
                break;
        }
    }

    lendNow(redirect_url: any) {
        // const lend_url = redirect_url;

        // this.router.navigate([redirect_url]);

        this.router.navigate(['/cash-loan'], {
            queryParams: {
                currenttab: 'lending'
            }
        });

        // this.discord_join_loading = true;

        // const body = {
        //     social_media_type: 'discord'
        // };

        // this.apiCall(body);
    }
    oracleLicense(redirect_url: any) {
        const oracle_license_url = redirect_url;

        this.window.open(oracle_license_url, '_blank');
    }
    connectDiscord(redirect_url: any) {
        const discord_url = redirect_url;

        // if (this.width > 768)
        this.window.open(discord_url, '_blank');

        this.discord_join_loading = true;

        const body = {
            social_media_type: 'discord'
        };

        this.apiCall(body);
    }

    connectTwitter(redirect_url: any) {
        // if (this.width > 768)
        this.window.open(redirect_url, '_blank');

        this.twitter_join_loading = true;

        const body = {
            social_media_type: 'twitter'
        };

        this.apiCall(body);
    }

    shareOnTwitter() {
        this.share_twitter_join_loading = true;

        const text = 'Join Credible Finance with my invitation link';
        const hashtags = [
            'credible', 'finance', 'crediblefinance', 'solana', 'plume', 'ethereum'
        ].join(',');
        const via = 'crediblefin';

        const url = `https://twitter.com/intent/tweet?text=${text}&url=${this.invitation_link}&hashtags=${hashtags}&via=${via}`;

        this.window.open(url, '_blank');

        this.onboardingService.shareOnTwitter().subscribe((res: any) => {
            this.share_twitter_join_loading = false;

            if (this.httpService.user.twitter)
                this.httpService.user.twitter.share_on_twitter = true;
        }, (err: HttpErrorResponse) => {
            this.share_twitter_join_loading = false;

            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    connectTelegram(redirect_url: any) {
        if (this.width > 768)
            this.window.open(redirect_url, '_blank');

        this.telegram_join_loading = true;

        const body = {
            social_media_type: 'telegram'
        };

        this.apiCall(body);
    }

    generateKycUrl() {
        // this.cfAlertService.showMessage('System is under maintenance.', true);

        // return;

        this.kyc_loading = true;

        this.kycService.generateKycUrl(this.httpService.user).subscribe((res: any) => {
            this.kyc_loading = false;

            this.window.location.href = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.kyc_loading = false;

            this.cfAlertService.showError(err);
        });
    }

    getUserTasks() {
        this.httpService.getUserTasks().subscribe((res: any) => {
            const tasks = res.data.tasks;

            this.multiplier_tasks = tasks.filter((task: any) => task.multiplier);
            this.non_multiplier_tasks = tasks.filter((task: any) => !task.multiplier);

            this.total_task_count = res.data.tasks_count;
            this.completed_task_count = res.data.completed_task_count;
            this.pending_task_count = res.data.pending_task_count;
            this.total_user_moon = res.data.total_user_moons;
            this.referred_user_count = res.data.referred_user_count;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getSpaceshipFaqQuestions() {
        this.httpService.getSpaceshipFaqQuestions().subscribe((res: any) => {
            this.questions = res.data;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    async apiCall(body: any) {
        const recaptcha = await this.recaptchaService.getRecaptcha('joinSocialMedia', this.use_manual_recaptcha);

        body = {
            ...body, 
            ...recaptcha
        };
        this.onboardingService.updateSocialMedia(body).subscribe((res: any) => {
            if (body.social_media_type === 'discord') {
                this.discord_join_loading = false;
                this.discordJoined = true;

                this.httpService.user.discord = {
                    id: '',
                    server_joined: true,
                    global_name: '',
                    username: ''
                    // id: 0
                };
            }

            if (body.social_media_type === 'twitter') {
                this.twitter_join_loading = false;
                this.twitterJoined = true;

                if (!this.httpService.user.twitter) {
                    this.httpService.user.twitter = {
                        group_joined: true,
                        share_on_twitter: false,
                        user_id: 0,
                        screen_name: ''
                    };
                }

                this.httpService.user.twitter.group_joined = true;
            }

            this.cfAlertService.showMessage(res.message);

            // if (this.twitterJoined && this.discordJoined)
            //     this.next();
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            if (err.error.message === 'Invalid recaptcha') {
                this.use_manual_recaptcha = true;
                this.apiCall(body);

                return ;
            }

            this.cfAlertService.showError(err);

            if (body.social_media_type === 'discord')
                this.discord_join_loading = false;

            if (body.social_media_type === 'twitter')
                this.twitter_join_loading = false;
        });
    }

    connectWallet() {
        this.connectWalletDialogRef = this.dialog.open(ConnectWalletComponent, {
            width: '550px',
            height: 'auto',
            data: {}
        });

        this.connectWalletDialogRef.afterClosed().subscribe((status: string) => {
            console.log('connectWallet afterClosed', status);

            this.window.location.reload();
        });
    }
}
