import { Injectable } from '@angular/core';
import {genesisServerUrl} from './app.config';
import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class GenesisService {
    cred_rate: number = 0;

    constructor(private localStorageService: LocalStorageService, private http: HttpClient) { }

    getCredReceivableStatsV2(body: any) {
        const token_required = body.token_required;
        const plan_id = body.plan_id;
        const duration = body.duration;

        if (!token_required) {
            return this.http.get<any>(
                `${genesisServerUrl}getCredReceivableStatsV2?plan_id=${plan_id}&duration=${duration}`
            );
        }
        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(
                `${genesisServerUrl}getCredReceivableStatsV2?plan_id=${plan_id}&duration=${duration}`,
                httpOptions
            );
        }
    }

    getCredRateChart(body: any) {
        const token_required = body.token_required;
        // const plan_id = body.plan_id;
        const duration = body.duration;

        if (!token_required) {
            return this.http.get<any>(
                `${genesisServerUrl}credRateChart?duration=${duration}`
            );
        }
        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(
                `${genesisServerUrl}credRateChart?duration=${duration}`,
                httpOptions
            );
        }
    }

    getUserPortfolioBalance() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}dashboard/getUserPortfolioBalance`,
            httpOptions
        );
    }

    getUserReferralEarnings() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}dashboard/getUserReferralEarnings`,
            httpOptions
        );
    }

    getUserRecentTransactions(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };
    
        return this.http.get<any>(
            `${genesisServerUrl}dashboard/getUserRecentTransactions?page=${page}&limit=${limit}`,
            httpOptions
        );
    }
    
    updateUserUnclaimedCredTokens() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };
    
        return this.http.post<any>(
            `${genesisServerUrl}dashboard/updateUserUnclaimedCredTokens`,
            {},
            httpOptions
        );
    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    getNewNodePredefinedData() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNewNodePredefinedData`,
            httpOptions
        );
    }

    getPlanDetails(plan_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getPlanDetails?plan_id=${plan_id}`,
            httpOptions
        );
    }

    getPlanDetailsChart(body: any) {
        const plan_id = body.plan_id;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getPlanDetailsChart?plan_id=${plan_id}`,
            httpOptions
        );
    }

    createNode(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}createNode`,
            body,
            httpOptions
        );
    }

    updateNode(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}updateNode`,
            body,
            httpOptions
        );
    }

    getDelegates() {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(
            `${genesisServerUrl}getDelegates`,
            httpOptions
        );
    }

    getIpLocation() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: localStorage.getItem('token') as string
            })
        };

        return this.http.get<any>(`${genesisServerUrl}getIpLocation`, httpOptions);
    }

    getPlanUnlockSchedule(plan_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getPlanUnlockSchedule?plan_id=${plan_id}`,
            httpOptions
        );
    }

    getPlanLoyaltySchedule(plan_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getPlanLoyaltySchedule?plan_id=${plan_id}`,
            httpOptions
        );
    }

    getNodes(data: any) {
        const page = data.page;
        const limit = data.limit;
        const plan_id = data.plan_id;
        const status = data.status;
        const closed = data.closed;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNodes?page=${page}&limit=${limit}&plan_id=${plan_id}&status=${status}&closed=${closed}`,
            httpOptions
        );
    }

    getCredRatePromise() {
        return new Promise<void>((resolve, reject) => {
            this.http.get<any>(`${genesisServerUrl}getCurrentCredRate`).subscribe(
                (res) => {
                    this.cred_rate = res.data.rate;

                    resolve();
                },
                (err) => {
                    console.error(err);

                    resolve();
                }
            );
        });
    }

    getNode(node_id: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNode?node_id=${node_id}`,
            httpOptions
        );
    }

    getNodeUnlockSchedule(node_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNodeUnlockSchedule?node_id=${node_id}`,
            httpOptions
        );
    }

    getNodeLoyaltySchedule(node_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNodeLoyaltySchedule?node_id=${node_id}`,
            httpOptions
        );
    }

    claimStakingReward(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}claimStakingReward`,
            body,
            httpOptions
        );
    }

    updateStakingRewardClaimSignature(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}updateStakingRewardClaim`,
            body,
            httpOptions
        );
    }

    getReferralRewardStats(filter: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getReferralRewardStats?filter=${filter}`,
            httpOptions
        );
    }

    getReferralRewards(data: any) {
        const page = data.page;
        const limit = data.limit;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getReferralRewards?page=${page}&limit=${limit}`,
            httpOptions
        );
    }

    getTopCredUsers(current_level: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${genesisServerUrl}getTopNodePurchaseUsers?level=${current_level}`, httpOptions);
    }

    getNodePurchaseStats(current_level: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${genesisServerUrl}getNodePurchaseStats?level=${current_level}`, httpOptions);
    }

    getNodePurchaseChart(current_level: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${genesisServerUrl}getNodePurchaseChart?level=${current_level}`, httpOptions);
    }

    getReferralBalance(currency: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getReferralBalance?currency=${currency}`,
            httpOptions
        );
    }

    claimReferralReward(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}claimReferralReward`,
            body,
            httpOptions
        );
    }

    updateReferralRewardClaim(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(
            `${genesisServerUrl}updateReferralRewardClaim`,
            body,
            httpOptions
        );
    }

    getReferralRewardWithdrawals(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getReferralRewardWithdrawals?page=${data.page}&limit=${data.limit}`,
            httpOptions
        );
    }

    getNodeStats(filter: string, token_required: boolean) {
        if (!token_required) {
            return this.http.get<any>(
                `${genesisServerUrl}getNodeStats?filter=${filter}`
            );
        }
        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(
                `${genesisServerUrl}getNodeStats?filter=${filter}`,
                httpOptions
            );
        }
    }

    getNodeTransactions(data: any) {
        const page = data.page;
        const limit = data.limit;
        const plan_id = data.plan_id;
        const status = data.status;
        const closed = data.closed;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getNodeTransactions?page=${page}&limit=${limit}&plan_id=${plan_id}&status=${status}&closed=${closed}`,
            httpOptions
        );
    }

    getStakingRewardWithdrawals(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${genesisServerUrl}getStakingRewardWithdrawals?page=${data.page}&limit=${data.limit}`,
            httpOptions
        );
    }
}
