<div class="app-staking-widget" [ngClass]="httpService.theme">
    <div class="staking-widget-sub-section">
        <div class="staking-widget-sub-section-tabs">
            <div class="button-container mb-4">
                <button type="button" class="button" [ngClass]="currentTab === 'rewards' ? 'active' :''"
                    (click)="changedTab('rewards')">
                    Rewards
                </button>
                <!-- <button type="button" class="button" [ngClass]="currentTab === 'staking' ? 'active' :''"
                    (click)="changedTab('staking')">
                    Stake
                </button> -->
                <button type="button" class="button" [ngClass]="currentTab === 'unstaking' ? 'active' :''"
                    (click)="changedTab('unstaking')">
                    Unstake
                </button>
            </div>
        </div>

        <app-new-staking-claim [data]="data" *ngIf="currentTab === 'rewards'"></app-new-staking-claim>
        <!-- <app-new-staking *ngIf="currentTab === 'staking'"></app-new-staking> -->
        <app-new-unstaking [data]="data" *ngIf="currentTab === 'unstaking'"></app-new-unstaking>

    </div>
</div>