import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { Router } from '@angular/router';
import IUser from '../../../interfaces/IUser';
import User from '../../../models/User';
import { HttpService } from '../../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import ICredPurchase from '../../../interfaces/ICredPurchase';
import { GenesisService } from '../../genesis.service';

@Component({
    selector: 'app-my-nodes-transactions',
    templateUrl: './my-nodes.component.html',
    styleUrls: [ './my-nodes.component.scss' ]
})

export class MyNodesTransactionsComponent implements OnInit {
    user: IUser = new User();
    loading: boolean = false;
    loading_stats: boolean = false;
    currentTime: number = (new Date()).getTime();
    nodes: Array<ICredPurchase> = [];
    stats: any = {
        all: {
            amount_usd: 0,
            count: 0
        },
        days_1: {
            amount_usd: 0,
            count: 0
        },
        days_7: {
            amount_usd: 0,
            count: 0
        },
        days_30: {
            amount_usd: 0,
            count: 0
        }
    };

    total_count: number = 0;
    page: number = 0;
    limit: number = 10;
    opened_panel: string = '';

    statusOptions: Array<IDropdown> = [{
        label: 'All',
        value: '',
        optionEnabled: true
    },
    {
        label: 'Pending',
        value: 'pending',
        optionEnabled: true
    },
    {
        label: 'Processing',
        value: 'processing',
        optionEnabled: true
    }, {
        label: 'Completed',
        value: 'completed',
        optionEnabled: true
    }, {
        label: 'Failed',
        value: 'failed',
        optionEnabled: true
    }];
    selected_status: string = 'completed';

    chart_loaded : boolean = false;
    chart_data: any;

    constructor( private genesisService: GenesisService, public httpService: HttpService, private cfAlertService: CfAlertService, private router: Router) { }

    ngOnInit(): void {
        console.log('my-purchases.component.ts ngOnInit()');

        this.subscribeObservables();

        this.chart_data = this.getDummyChartData();
        this.chart_loaded = true;

        this.httpService.setMetaData('My Investments', 'Investments made by the user');
    }

    afterExpand(lending_id: string) {
        this.opened_panel = lending_id;
    }

    getNodeStats(filter: string) {
        this.loading_stats = true;

        this.genesisService.getNodeStats(filter, true).subscribe(res => {
            this.stats[filter] = res.data;

            this.loading_stats = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);

            this.loading_stats = false;
        });
    }

    getNodes() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            token_required: true,
            status: this.selected_status
        };

        this.genesisService.getNodeTransactions(body).subscribe((res: any) => {
            this.nodes = res.data;
            this.total_count = res.total_count;

            if (this.nodes.length > 0)
                this.opened_panel = this.nodes[0].node_id;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    subscribeObservables() {
        if (this.httpService.user.user_id > 0) {
            this.httpService.getUserId().subscribe((user_id: number) => {
                this.user.user_id = user_id;

                this.getNodes();
                this.getNodeStats('all');
                this.getNodeStats('days_1');
                this.getNodeStats('days_7');
                this.getNodeStats('days_30');
            });
        }
    }

    pageChanged(event: any) {
        this.page = event;
        this.getNodes();
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changeStatus(event: any) {
        this.selected_status = event.value;
        this.page = 0;
        this.getNodes();
    }

    redirectToNodeDetail(node: ICredPurchase) {
        this.router.navigate([ '/my-nodes', node.node_id ]);
    }

    getDummyChartData() {
        const data = [];

        let current_time = new Date().setSeconds(0, 0);

        for (let i = 0; i < 30; i++) {
            data.push({
                x: current_time,
                y: 0
            });

            current_time = new Date(
                current_time - 24 * 60 * 60 * 1000
            ).setSeconds(0, 0);
        }

        return data;
    }
    getStatusClasses(id: string, status: string) {
        const classes: any = {};

        if (this.opened_panel === id)
            classes['current-opened'] = true;

        if (status === 'pending')
            classes['status-pending'] = true;

        else if (status === 'processing')
            classes['status-processing'] = true;

        else if (status === 'completed')
            classes['status-success'] = true;

        else if (status === 'failed')
            classes['status-failed'] = true;

        return classes;
    }
}

