<div class="app-ambassador-leaderboard">
    <div class="top-section">
        <div class="section-heading">
            Top CRED Members
        </div>
        <div class="level-dropdown" matTooltip="Referral Level">
            <cf-dropdown [options]="levelOptions" [showLogo]="false" [showLabel]="false" (change)="levelChange($event)"
                [selected]="current_level"></cf-dropdown>
        </div>
    </div>

    <div class="top-cred-members box">
        <table>
            <thead>
                <tr>
                    <th>Rank</th>
                    <th>Email Address</th>
                    <th>Level</th>
                    <th>CRED purchased</th>
                </tr>
            </thead>
            <tbody *ngIf="topCredUsers.length > 0">
                <tr *ngFor="let item of topCredUsers; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.email }}</td>
                    <td>Level {{ current_level }}</td>
                    <td>${{ item.payment_amount_usd | number
                        : httpService.getPrecision('usd') }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="topCredUsers.length === 0" class="no-data-tbody">
                <tr>
                    <td colspan="4">
                        <div class="no-data-td">No data available</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>