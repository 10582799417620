<div class="app-plan-details" [ngClass]="httpService.theme">
    <div class="row">
        <div class="controls">
            <cf-button [theme]="httpService.theme" type="solid" [label]="'My Nodes'" (clicked)="myNodes()"></cf-button>
        </div>
    </div>
    <div class="row">
        <div class="left col-md-7">
            <cf-card [theme]="httpService.theme" type="empty" *ngIf="chart_loaded">
                <div class="chart-controls">
                    <span class="card-heading">vCRED UNLOCK SCHEDULE</span>
                    <!-- <div class="chart-duration" matTooltip="Chart step size">
                        <cf-dropdown [options]="graphOptions" [showLogo]="false" [showLabel]="false"
                            (change)="graphDurationChange($event)" [selected]="currentGraphOption"></cf-dropdown>
                    </div> -->
                </div>

                <div class="chart">
                    <app-line-chart [lineConfig]="chartLineConfig" title="" [currency]="'vCRED'"></app-line-chart>
                </div>
            </cf-card>
        </div>
        <div class="right col-md-5">
            <app-new-node (changePlanId)="changePlanIdHandler($event)" [nodes]="nodes"></app-new-node>
        </div>
    </div>
    <div class="bottom" *ngIf="plan_id !== ''">
        <cf-card [theme]="httpService.theme" type="empty" class="mt-6">
            <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
                (changed)="changeTab($event)"></cf-tabs>

            <div class="tabs">
                <app-plan-unlock-schedule *ngIf="currentTab === 'vesting-schedule'"
                    [plan_id]="plan_id"></app-plan-unlock-schedule>
                <app-plan-loyalty-schedule *ngIf="currentTab === 'loyalty-schedule'"
                    [plan_id]="plan_id"></app-plan-loyalty-schedule>
            </div>
        </cf-card>
    </div>
    <app-frequently-asked-questions></app-frequently-asked-questions>
</div>