<div class="app-homepage">
    <div class="section-1">
        <div class="background">
            <video #videoPlayerHeader class="video" muted loop playsinline autoplay preload="auto"
                style="pointer-events: none; display: block;">
                <source src=" https://assets.credible.finance/HologramAlien1.mp4" type="video/mp4">
            </video>
            <div class="top-section">
                <div class="title">
                    <span>The First Decentralized<br />AI Credit System</span>
                </div>
                <span class="subtitle">Build onchain credit score to get cash loans, credit card & pay later.</span>
                <div class="button-section">
                    <cf-button label="Buy Node" type="theme" (clicked)="gotoURL('/nodes')"></cf-button>
                    <cf-button label="Try Beta" type="outline" (clicked)="gotoURL('/airdrop')"></cf-button>
                </div>
            </div>
            <div class="arrow-section">
                <img class="arrow-img" src="https://assets.credible.finance/DownArrow1.gif" />
            </div>
            <div class="arrow-section">
                <img class="arrow-img" src="https://assets.credible.finance/DownArrow1.gif" />
            </div>
        </div>
    </div>

    <div class="backers-partners-section" id="ecosystem">
        <div class="title">Backers and Partners</div>
        <div class="box-container">
            <div class="box partnets_img" *ngFor="let item of partners_array; let i = index">
                <a [href]="item.redirection_url">
                    <img class="img" [src]="item.img_url" [alt]="'Image ' + (i + 1)" />
                </a>
            </div>
        </div>
    </div>



    <div class="section-ai-infrastructure">
        <div class="our-solution">
            <div class="content">
                <div class="left-section">
                    <div class="left-section-heading">Earn with AI-powered Credit Infrastructure</div>
                    <div class="left-section-description">
                        Own a stake in the future of lending with CreditAI—an intelligent, AI-powered, oracle-based
                        architecture designed to create credit histories and scores for on-chain wallets. Get started by
                        owning your node.
                    </div>
                    <!-- <div class="bullet-points">
                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Lorem ipsum dolor sit amet</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Lorem ipsum dolor sit amet consectetur. Elit in.</span>
                        </div>

                    </div> -->
                    <div class="controls gap-2">
                        <!-- <cf-button label="Buy Node"
                            (clicked)="redirectToInternalUrl('https://credible.gitbook.io/docs/introduction/oracleai')"></cf-button> -->
                        <cf-button label="Get Started" (clicked)="gotoURL('/airdrop')"></cf-button>
                        <cf-button label="Learn More" type="theme"
                            (clicked)="redirectToInternalUrl('https://crediblefinance.notion.site/creditai-infra')"></cf-button>
                    </div>
                </div>
                <div class="right-section">
                    <video #videoPlayer width="70%" muted loop playsinline autoplay preload="auto"
                        style="pointer-events: none; display: block;">
                        <source src="https://assets.credible.finance/ai.mp4" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>

    <div class="section-5">
        <div class="heading">Secure & Transparent</div>
        <div class="content">
            <div class="protocol-security-card">
                <div class="heading">Protocol Security</div>
                <div class="description">All our smart contracts are audited by Sec3. All are institutional borroweres
                    are rated by Credora</div>
                <div class="image">
                    <div class="image-holder">
                        <img src="https://assets.credible.finance/Credoralogo.png" alt="Credora" title="Credora"
                            loading="lazy" />
                        <span class="image-label">Credora rated</span>
                    </div>
                    <div class="image-holder">
                        <img src="https://assets.credible.finance/Sec3.png" alt="Sec3" title="Sec3" loading="lazy" />
                        <span class="image-label">Sec3 Audited</span>
                    </div>
                </div>
            </div>

            <div class="transactions-card">
                <div class="heading">Transparent</div>
                <div class="description">All transactions are on-chain publicly tracked</div>
                <div class="transactions">
                    <div class="transaction" *ngFor="let transaction of transactions"
                        (click)="httpService.redirectionTx(transaction.blockchain, transaction.chain, transaction.network, transaction.blockchain_txid)">
                        <div class="transaction-line-1">
                            <div class="transaction-amount">+{{ transaction.amount | number : "0.0-2" }} {{
                                transaction.currency | uppercase }}</div>
                            <div class="transaction-txid">{{ transaction.blockchain_txid }}</div>
                        </div>
                        <div class="transaction-line-2">
                            <div class="transaction-usd-value">
                                <span class="dollar-sign">$</span>
                                {{ transaction.amount_usd | number:'0.0-2' }}
                            </div>
                            <div class="transaction-date">{{ transaction.created | date : 'mediumDate' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-4">
        <div class="our-solution">
            <div class="heading">Our Solution</div>

            <div class="content">
                <div class="left-section">
                    <!-- <div class="left-section-sub-heading">Business Solutions</div> -->
                    <div class="left-section-heading">Borrow Cash against Crypto & Tokenized RWAs</div>
                    <div class="left-section-description">
                        Credit line against your Phantom & Metamask portfolio, withdraw cash to your bank account or pay
                        third-party invoices.
                    </div>

                    <!-- <div class="bullet-points">
                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Credit assessed & rated borrowers</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Fintechs, NBFIs and real world applications as borrowers</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Short term opportunities, max 3-months lockup</span>
                        </div>
                    </div> -->

                    <div class="controls">
                        <cf-button label="Get Cash" type="theme" (clicked)="redirectToBorrow()"></cf-button>
                    </div>
                </div>
                <div class="right-section">
                    <img src="https://assets.credible.finance/IMG444.png" alt="Credible" title="" loading="lazy" />
                </div>
            </div>
        </div>

        <div class="borrow">
            <div class="content">
                <div class="right-section">
                    <img src="https://assets.credible.finance/Investmentsolutionnew.png" alt="Credible" title=""
                        loading="lazy" />
                </div>
                <div class="left-section">
                    <!-- <div class="left-section-sub-heading">Retail Solutions</div> -->
                    <div class="left-section-heading">Generate Yields on Stablecoins</div>
                    <div class="left-section-description">
                        Lend to stablecoin PayFi pools and earn fixed + exponential yields in stablecoins and CRED
                        points.
                    </div>

                    <!-- <div class="bullet-points">
                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Licensed NBFIs & VASPs</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Fiat loans for healthcare & trade finance</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Fiat loans for crypto collateral</span>
                        </div>
                    </div> -->

                    <div class="controls">
                        <cf-button label="Start Earning" type="theme" (clicked)="redirectToLend()"></cf-button>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="controls">
            <cf-button label="Learn more" type="theme"
                (clicked)="redirectToInternalUrl('https://credible.gitbook.io/docs/introduction/payfi')"></cf-button>
        </div> -->
    </div>



    <!-- <div class="section-6">
        <div class="heading">Ecosystem</div>
        <div class="description">A fintech powered by DeFi</div>

        <div class="ecosystem-image">
            <img src="https://assets.credible.finance/EcosystemImage.png" alt="Ecosystem" title="Ecosystem"
                loading="lazy" />
        </div>
    </div> -->

    <!-- <div class="section-7">
        <div class="our-solution">
            <div class="content">
                <div class="left-section">
                    <div class="left-section-sub-heading">Fintech</div>
                    <div class="left-section-heading">Consumer & SME Finance</div>
                    <div class="left-section-description">
                        In partnership with NBFIs in India and UAE, we are offering healthcare consumer loans and SME
                        trade finance loans.
                    </div>

                    <div class="controls">
                        <cf-button label="Coming soon" type="theme"></cf-button>
                    </div>

                    <div class="animated-image">
                        <img src="https://assets.credible.finance/Image55.png" alt="Apply Now" title="Apply Now"
                            loading="lazy" />
                    </div>

                    <div class="mobile-apps">
                        <img src="https://assets.credible.finance/image31.png" alt="Play store" title="Play store"
                            loading="lazy" />

                        <img src="https://assets.credible.finance/image41.png" alt="App store" title="App store"
                            loading="lazy" />
                    </div>
                </div>
                <div class="right-section">
                    <img src="https://assets.credible.finance/FintechImage.png" alt="Mobile app" title="Mobile app"
                        loading="lazy" />
                </div>
            </div>
        </div>
    </div> -->

    <div class="section-8">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="logo" [routerLink]="'/'">
                    <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                        title="Credible Finance" loading="lazy" (click)="scrollUp()" />
                </div>

                <div class="community">
                    <a href="https://twitter.com/crediblefin" target="_blank">
                        <i class="fa-brands fa-twitter"></i>
                    </a>
                    <a href="https://t.me/crediblefinance" target="_blank">
                        <i class="fa-brands fa-telegram"></i>
                    </a>
                    <a href="https://medium.com/@credible_finance" target="_blank">
                        <i class="fa-brands fa-medium"></i>
                    </a>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <div class="row">
                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Product</span>
                            <span class="links-label" routerLink="/liquidity">Private Credit</span>
                            <span class="links-label" routerLink="/cash-loan">PayFi</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Community</span>
                            <a href="https://twitter.com/crediblefin" target="_blank" class="links-label">Twitter</a>
                            <a href="https://t.me/crediblefinance" target="_blank" class="links-label">Telegram</a>
                            <a href="https://medium.com/@credible_finance" target="_blank"
                                class="links-label">Medium</a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Resources</span>
                            <a href="https://credible.gitbook.io/" target="_blank" class="links-label">Whitepaper</a>
                            <!-- <a href="https://docsend.com/view/c4nm6fpy6kfk2vez" target="_blank"
                                class="links-label">Litepaper</a>
                            <span class="links-label">Developer Docs</span> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Legal</span>
                            <a (click)="redirectToUrl('/privacy-policy')" target="_blank" class="links-label">Privacy
                                Policy</a>
                            <a (click)="redirectToUrl('/terms-condition')" target="_blank" class="links-label">Terms
                                And Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>