import {
    Component, Input, OnInit 
} from '@angular/core';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-frequently-asked-questions',
    templateUrl: './frequently-asked-questions.component.html',
    styleUrl: './frequently-asked-questions.component.scss'
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
    openedFaqIndex: string = '-1';
    faqs: Array<{
        question: string;
        answer: string;
        index: string;
    }> = [];
    // 

    @Input() theme: string = 'dark';

    constructor(public httpService: HttpService) {}

    ngOnInit(): void {
        this.getFaqs();
    }

    getFaqs() {
        this.faqs = [
            {
                index: '0',
                question:
                    'What is the Credible platform?',
                answer: 'Credible is a decentralized blockchain-based ecosystem designed to modernize credit systems by introducing tokenized debt and equity markets. With its oracle network, Credible Nodes, the platform provides data validation, staking rewards, and governance, creating a transparent and secure infrastructure for its users.'
            },
            {
                index: '1',
                question:
                    'What is $vCRED, and how does it work?',
                answer: '$vCRED is a vested form of Credible’s $CRED token. It plays a central role in Credible’s ecosystem, allowing users to participate in staking, governance, and reward distribution. By staking $vCRED, holders earn rewards and help secure the network. Additionally, larger purchases of $vCRED come with reward multipliers to encourage deeper involvement and network stability.'
            },
            {
                index: '2',
                question:
                    'How can I earn rewards with vCRED?',
                answer: 'By staking $vCRED with Credible’s nodes, users earn daily staking rewards based on the amount they’ve staked and the node’s performance. Rewards are distributed proportionally, with larger stakes often earning higher returns. Additionally, users are encouraged to sell accrued rewards rather than unstaking, as unstaking can reduce overall network reward rates.'
            },
            {
                index: '3',
                question:
                    'What are the multipliers associated with vCRED purchases?',
                answer: 'Credible offers a tiered multiplier system for $vCRED purchases, incentivizing larger investments. For instance, a $100 vCRED purchase comes with a 1.1x multiplier, while a $100,000 investment comes with a 2x multiplier and eligibility to run a masternode. These multipliers provide additional $vCRED on top of the initial purchase, rewarding higher commitment to the platform.'
            },
            {
                index: '4',
                question:
                    'What is the role of masternodes in the Credible ecosystem?',
                answer: 'Masternodes in Credible serve as decentralized oracles that validate and relay data for the network, particularly for tokenized debt and equity markets. Masternode operators, or delegates, must stake a minimum amount of $vCRED and are rewarded for their role in maintaining the network’s data integrity and stability.'
            },
            {
                index: '5',
                question:
                    'How does the governance model work for Credible?',
                answer: 'Governance in Credible is community-driven, with $vCRED holders participating in decision-making processes for protocol upgrades, staking reward adjustments, and other strategic moves. This model, known as Genesis Governance, ensures that key decisions reflect the community’s interests and promote long-term sustainability.'
            }
        ];
    }

    openFaq(index: string) {
        if (this.openedFaqIndex === index) this.openedFaqIndex = '-1';
        else this.openedFaqIndex = index;
    }
}
