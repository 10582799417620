import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, ViewChild 
} from '@angular/core';
import {
    ActivatedRoute, Router 
} from '@angular/router';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';

import { getWindow } from 'ssr-window';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import Node from '../../models/Node';
import INode from '../../interfaces/INode';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import { DecimalPipe } from '@angular/common';
import ILineConfig from '../charts/line-chart/ILineConfig';
import { GenesisService } from '../genesis.service';

@Component({
    selector: 'app-plan-details',
    templateUrl: './plan-details.component.html',
    styleUrl: './plan-details.component.scss'
})
export class PlanDetailsComponent implements OnInit {
    plan_id: string = '';

    loading: boolean = false;

    node: INode = new Node();
    window: Window;
    
    chartLineConfig: Array<ILineConfig> = [];

    tabs: Array<TabOption> = [{
        label: 'Vesting Schedule',
        value: 'vesting-schedule'
    }, {
        label: 'Loyalty Schedule',
        value: 'loyalty-schedule'
    }];
    currentTab = this.tabs[0].value;

    total_cred_points: number = 0;

    chart_loaded: boolean = false;
    stats: any;
    graphArray: any = [];
    chartTitle = '';
    chartData: any;
    isTooltipVisible = false;
    additionalYieldLabel: string = 'vCRED';
    nodes: Array<INode> = [];

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(
        private route: ActivatedRoute,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private genesisService: GenesisService,
        private decimalPipe: DecimalPipe,
        private router: Router
    ) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        this.getNewNodePredefinedData();

        window.scrollTo(0, 0);
    }

    getPlanDetailsChart() {
        this.chart_loaded = false;

        const body = {
            plan_id: this.plan_id 
        };

        this.genesisService.getPlanDetailsChart(body).subscribe((res: any) => {
            this.chartData = res.data;
            this.chartLineConfig = [{
                name: '',
                data: res.data,
                lineColor: '#cf0'
            }];

            this.chart_loaded = true;
        }, (err: HttpErrorResponse) => {
            console.error(err);
            this.cfAlertService.showError(err);
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getNewNodePredefinedData() {
        this.genesisService.getNewNodePredefinedData().subscribe((res) => {
            this.loading = false;

            this.nodes = res.data;
            this.node = this.nodes[0];

            this.httpService.setMetaData(
                `${this.decimalPipe.transform(this.node.cred_receivable, '0.0-0')} vCRED`,
                this.node.description
            );

            this.plan_id = this.node.plan_id;

            this.getPlanDetailsChart();
        },
        (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changePlanIdHandler(event: any) {
        console.log(this.constructor.name, 'changePlanIdHandler', event);

        this.plan_id = event;

        this.getPlanDetailsChart();
    }

    myNodes() {
        this.router.navigate([ '/nodes/my-nodes' ]);
    }
}
