import {
    Inject, Injectable, PLATFORM_ID
} from '@angular/core';
import {
    HttpClient, HttpHeaders
} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import {
    DecimalPipe, isPlatformBrowser
} from '@angular/common';
import {
    Meta, Title
} from '@angular/platform-browser';
import IUser from '../interfaces/IUser';
import User from '../models/User';
import { LocalStorageService } from './localstorage.service';
import {
    getWindow, getDocument
} from 'ssr-window';
import {
    serverUrl, currencyConversionMapUrl,
    socketUrl,
    userServerUrl
} from '../app/app.config';
import { io } from 'socket.io-client';
import { WalletService } from './wallet.service';
import { nullChecker } from '../helpers/nullChecker';
import getRedirectionTxUrl from '../helpers/getRedirectionTxUrl';
import getRedirectionAddressUrl from '../helpers/getRedirectionAddressUrl';
import IPrivateCreditPool from '../interfaces/IPrivateCreditPool';
import {
    NavigationExtras, Router
} from '@angular/router';
import queryString from 'query-string';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    currencyConversionMap: any = {};
    visualPrecision: any = {};
    user: IUser = new User();
    window: Window;
    document: Document;

    account = new BehaviorSubject({
        address: '',
        isConnected: false
    });

    user_id: BehaviorSubject<number> = new BehaviorSubject(0);

    socket: any;
    socketConnected: boolean = false;
    balances: any = {};
    countries: any = [];

    route_change: BehaviorSubject<any> = new BehaviorSubject(false);

    theme: string = 'dark';
    themeChanged: BehaviorSubject<string> = new BehaviorSubject(this.theme);
    platformId: object = {};

    constructor(
        private http: HttpClient,
        private decimalPipe: DecimalPipe,
        private titleService: Title,
        private metaService: Meta,
        private localStorageService: LocalStorageService,
        private walletService: WalletService,
        private router: Router,
        @Inject(PLATFORM_ID) platformId: object) {
        this.platformId = platformId;

        this.window = getWindow();
        this.document = getDocument();

        this.connectSocket();

        this.window.addEventListener('storage', (event) => {
            if (event.key === 'token') {
                console.log('event', event);
                this.connectSocket();
            }
        });
    }

    connectSocket() {
        if (!isPlatformBrowser(this.platformId))
            return;

        let socketURL = socketUrl.slice(0, socketUrl.length - 1) + '?platform=web';

        if (this.localStorageService.getItem('token'))
            socketURL += `&token=${this.localStorageService.getItem('token')}`;

        console.log('this.socketConnected >>', this.socketConnected, socketURL);

        if (this.socketConnected) {
            console.log('socket already connected');

            return;
        }

        console.log('this.socketConnected >>', this.socketConnected);

        this.socket = io(socketURL, {
            transports: [ 'websocket' ]
        });

        this.socket.on('connect', () => {
            console.log('socket connect');

            this.socketConnected = true;
        });

        this.socket.on('error', (err: any) => console.error(err));

        this.socket.on('connect_error', (err: any) => {
            console.log('socket connect_error', err);
        });

        this.socket.on('disconnect', (reason: any) => {
            console.log('socket disconnect', reason);

            this.socketConnected = false;
        });

        this.socket.on('deployment', () => {
            location.reload();
        });

        console.log('this.socketConnected >>', this.socketConnected);
    }

    getCurrencyConversionMap() {
        return new Promise<void>((resolve, reject) => {
            this.http.get<any>(currencyConversionMapUrl).subscribe(res => {
                res.data.pusd = res.data.usdc;
                res.data.PUSD = res.data.USDC;

                this.currencyConversionMap = res.data;

                resolve();
            }, (err) => {
                console.error(err);

                resolve();
            });
        });
    }

    setCurrenciesVisualPrecision() {
        return new Promise<void>((resolve, reject) => {
            resolve();

            this.visualPrecision = {
                usdt: 4,
                USDT: 4,
                usdc: 4,
                inr: 2,
                INR: 2,
                aed: 2,
                AED: 2,
                sgd: 2,
                SGD: 2,
                php: 2,
                PHP: 2,
                USDC: 4,
                sol: 4,
                SOL: 4,
                usd: 2,
                USD: 2
            };
        });
    }

    setMetaData(title: string, description: string = '', image: string = '') {
        const _title = title === '' ? 'Credible Finance' : `${title} | Credible Finance`;
        const _description = description === '' ? _title.slice(0, 160) : description.slice(0, 160);
        const _image = image === '' ? 'https://assets.credible.finance/CredibleWhitelogo1.png' : image;

        this.titleService.setTitle(_title);

        // Google
        this.metaService.addTag({
            name: 'description',
            content: _description
        });
        this.metaService.addTag({
            name: 'keywords',
            content: ''
        });
        this.metaService.addTag({
            name: 'author',
            content: 'Credible Finance'
        });
        this.metaService.addTag({
            name: 'application-name',
            content: 'Credible Finance'
        });
        this.metaService.addTag({
            name: 'copyright',
            content: '2023 - 2024'
        });

        // Twitter
        this.metaService.addTag({
            name: 'twitter:title',
            content: _title
        });
        this.metaService.addTag({
            name: 'twitter:description',
            content: _description
        });
        this.metaService.addTag({
            property: 'twitter:image',
            content: _image
        });
        this.metaService.addTag({
            property: 'twitter:card',
            content: 'summary'
        });

        // Facebook
        this.metaService.addTag({
            property: 'og:title',
            content: _title
        });
        this.metaService.addTag({
            property: 'og:description',
            content: _description
        });
        this.metaService.addTag({
            property: 'og:image',
            content: _image
        });
        this.metaService.addTag({
            property: 'og:type',
            content: 'article'
        });
        this.metaService.addTag({
            property: 'og:url',
            content: this.window.location.href
        });

        this.metaService.addTag({
            name: 'robots',
            content: 'index, follow'
        });

        this.setCanonicalUrl(this.window.location.href);
    }

    setCanonicalUrl(url: string) {
        const links = this.document.querySelectorAll('link[rel="canonical"]');

        if (links.length > 0)
            links[0].setAttribute('href', url);
    }

    getCurrentUser(callback: any = null) {
        // returning a promise is mandatory for app initializer service
        return new Promise<void>((resolve) => {
            if (!this.localStorageService.getItem('token')) {
                if (callback)
                    callback();

                resolve();

                return;
            }

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    token: this.getAuthToken()
                })
            };

            this.http.get<any>(`${userServerUrl}` + 'getCurrentUser', httpOptions).subscribe(res => {
                this.user = res.data;

                if (callback)
                    callback();

                console.log('getcurrentuser', this.user);

                resolve();
            }, err => {
                console.error(err.error);

                if (err && err.error && err.error.code === 1700) {
                    this.localStorageService.clear();

                    if (callback)
                        callback();

                    resolve();
                }
                else {
                    if (callback)
                        callback();

                    resolve();
                }
            });
        });
    }

    getUserBalances() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        this.http.get<any>(`${serverUrl}getUserBalances`, httpOptions).subscribe((res) => {
            this.balances = res.data;
        });
    }

    setUserId(user_id: number) {
        return this.user_id.next(user_id);
    }

    getUserId() {
        return this.user_id.asObservable();
    }

    //commmon
    getCurrencyUrl(currency: string) {
        if (nullChecker(currency))
            return '';

        currency = currency.toLowerCase();

        return 'https://assets.credible.finance/currencies/' + currency + '.png';
    }

    getPrecision(currency: string) {
        if (this.visualPrecision[currency])
            return `0.0-${this.visualPrecision[currency]}`;

        return '0.0-2';
    }

    getInrFormatting(input: number) {
        return this.decimalPipe.transform(input, '0.0-0');
    }

    getUsdFormatting(input: number) {
        return this.decimalPipe.transform(input, '0.0-2');
    }

    toFixedNumber(input: number, digits: number, base: number) {
        const pow = Math.pow(base || 10, digits);

        return Math.round(input * pow) / pow;
    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    sliceAddress(str: string, length: number = 5) {
        if (!str)
            return '';

        return str.slice(0, length) + '...' + str.slice(-length);
    }

    getMinMaxInterestRatePool(pool: IPrivateCreditPool) {
        let min = 100;
        let max = -100;

        pool.lending_yield_rates.forEach((x: any) => {
            if (min > x.apy)
                min = x.apy;

            if (max < x.apy)
                max = x.apy;
        });

        return {
            min,
            max
        };
    }

    getVaults(data: any) {
        if (nullChecker(data))
            data = {};

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getVaults?pool_id=${data.pool_id}`, httpOptions);
    }

    getCategories() {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getCategories`, httpOptions);
    }

    getPools(data: any) {
        if (nullChecker(data))
            data = {};

        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPools?category_id=${data.category_id}`, httpOptions);
    }

    getPoolDetails(pool_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPoolDetails?pool_id=${pool_id}`, httpOptions);
    }

    getPoolRewards(data: any) {
        const pool_id = data.pool_id;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getPoolRewards?pool_id=${pool_id}`, httpOptions);
    }

    getPoolActivity(data: any) {
        const page = data.page;
        const limit = data.limit;
        const pool_id = data.pool_id;

        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getPoolActivity?page=${page}&limit=${limit}&pool_id=${pool_id}`, httpOptions);
    }

    getVaultAllocation(data: any) {
        const page = data.page;
        const limit = data.limit;
        const vault_id = data.vault_id;

        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getVaultAllocation?page=${page}&limit=${limit}&vault_id=${vault_id}`, httpOptions);
    }

    getPoolWithdrawals(data: any) {
        const page = data.page;
        const limit = data.limit;
        const pool_id = data.pool_id;

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getPoolWithdrawals?page=${page}&limit=${limit}&pool_id=${pool_id}`, httpOptions);
    }

    getInvestments(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getInvestments?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getInvestments?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getClaimedInterests(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getClaimedInterests?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getClaimedInterests?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}`, httpOptions);
        }
    }

    getHomepageStats() {
        return this.http.get<any>(`${serverUrl}getHomepageStats`);
    }

    getPrincipalWithdrawals(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawals?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawals?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}`, httpOptions);
        }
    }

    getLendingCurrencies(blockchain: string, chain: string) {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getLendingCurrencies?blockchain=${blockchain}&chain=${chain}`, httpOptions);
    }

    getInvestmentStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getInvestmentStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getInvestmentStats?filter=${filter}`, httpOptions);
        }
    }

    getClaimedInterestStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getClaimedInterestStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getClaimedInterestStats?filter=${filter}`, httpOptions);
        }
    }

    getPrincipalWithdrawalStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawalStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getPrincipalWithdrawalStats?filter=${filter}`, httpOptions);
        }
    }

    getLoyaltyPoints() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getLoyaltyPointsV2`, httpOptions);
    }

    getLoyaltyLeaderboard(limit: number, page: number) {
        const httpOptions = {};

        return this.http.get<any>(`${serverUrl}getLoyaltyLeaderboardV2?limit=${limit}&page=${page}`, httpOptions);
    }

    redirectionTx(blockchain: string, chain: string, network: string, blockchain_txid: string) {
        const url = getRedirectionTxUrl(blockchain, chain, network, blockchain_txid);

        this.window.open(url, '_blank');
    }

    redirectionAddress(blockchain: string, chain: string, network: string, address: string) {
        const url = getRedirectionAddressUrl(blockchain, chain, network, address);

        this.window.open(url, '_blank');
    }

    getNfts() {
        const httpOptions = {
            headers: new HttpHeaders({})
        };

        return this.http.get<any>(`${serverUrl}getNfts`, httpOptions);
    }

    getPoolStats(pool_id: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}payfi/getPoolStats?pool_id=${pool_id}`, httpOptions);
    }

    getLendUserPortfolioCharts() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserPortfolioCharts`, httpOptions);
    }

    getLendUserActivity(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserActivity?page=${page}&limit=${limit}`, httpOptions);
    }

    getLendUserRecentTransactions(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserRecentTransactions?page=${page}&limit=${limit}`, httpOptions);
    }

    getLendUserPoolStats(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserPoolStats?page=${page}&limit=${limit}`, httpOptions);
    }

    getLendUserAssets(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}lendDashboard/getUserAssets?page=${page}&limit=${limit}`, httpOptions);
    }

    getBorrowUserPortfolioCharts() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserPortfolioCharts`, httpOptions);
    }

    getBorrowUserActivity(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserActivity?page=${page}&limit=${limit}`, httpOptions);
    }

    getBorrowUserRecentTransactions(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserRecentTransactions?page=${page}&limit=${limit}`, httpOptions);
    }

    getBorrowUserPoolStats(page: number, limit: number) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}borrowDashboard/getUserPoolStats?page=${page}&limit=${limit}`, httpOptions);
    }

    updateLendUserUnclaimedInterest(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}lendDashboard/updateUserUnclaimedInterest`, body, httpOptions);
    }

    updateUserRepaymentAmount(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}borrowDashboard/updateUserRepaymentAmount`, body, httpOptions);
    }

    getDrawdowns(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;
        const usage = data.usage;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getDrawdowns?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}&usage=${usage}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getDrawdowns?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}&usage=${usage}`, httpOptions);
        }
    }

    getDrawdownStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getDrawdownStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getDrawdownStats?filter=${filter}`, httpOptions);
        }
    }

    getRepayments(data: any) {
        const token_required = data.token_required;

        const page = data.page;
        const limit = data.limit;
        const category_id = data.category_id;
        const pool_id = data.pool_id;
        const status = data.status;

        if (!token_required)
            return this.http.get<any>(`${serverUrl}getRepayments?page=${page}&limit=${limit}&category_id=${category_id}&pool_id=${pool_id}&status=${status}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getRepayments?page=${page}&limit=${limit}&pool_id=${pool_id}&status=${status}`, httpOptions);
        }
    }

    getRepaymentStats(filter: string, token_required: boolean) {
        if (!token_required)
            return this.http.get<any>(`${serverUrl}getRepaymentStats?filter=${filter}`);

        else {
            const httpOptions = {
                headers: new HttpHeaders({
                    token: this.getAuthToken()
                })
            };

            return this.http.get<any>(`${serverUrl}getRepaymentStats?filter=${filter}`, httpOptions);
        }
    }

    createInvestment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}createInvestment`, body, httpOptions);
    }

    approveAllowance(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}approveAllowance`, body, httpOptions);
    }

    updateInvestment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateInvestment`, body, httpOptions);
    }

    claimInterest(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}claimInterest`, body, httpOptions);
    }

    updateInterestWithdrawal(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateInterestWithdrawal`, body, httpOptions);
    }

    requestPrincipalWithdrawal(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}requestPrincipalWithdrawal`, body, httpOptions);
    }

    updatePrincipalWithdrawal(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updatePrincipalWithdrawal`, body, httpOptions);
    }

    createDrawdown(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}createDrawdown`, body, httpOptions);
    }

    updateDrawdown(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateDrawdown`, body, httpOptions);
    }

    createRepayment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}createRepayment`, body, httpOptions);
    }

    updateRepayment(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}updateRepayment`, body, httpOptions);
    }

    getMembers(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${userServerUrl}getMembers?page=${data.page}&limit=${data.limit}&level=${data.level}`, httpOptions);
    }

    getRiskScore() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getRiskScore`, httpOptions);
    }

    getSupportedWallets() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getSupportedWallets`, httpOptions);
    }

    getCurrencyBalances() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getCurrencyBalances`, httpOptions);
    }

    getCurrencyBalance(blockchain: string, chain: string, wallet_address: string, mint_address: string | null = null) {
        const data: any = {
            blockchain,
            mint_address,
            chain
        };

        if (!nullChecker(wallet_address))
            data.wallet_address = wallet_address;

        if (mint_address)
            data.mint_address = mint_address;

        const queryString = new URLSearchParams(data).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getCurrencyBalanceV2?${queryString}`, httpOptions);
    }

    displayBankAccountNumber(account_number: string) {
        let temp = '';

        const step = 4;

        for (let i = 0; i < account_number.length; i = i + step) {
            temp += account_number.slice(i, i + step);

            if (i < account_number.length - 4)
                temp += '  ';
        }

        return temp;
    }

    getCountryFlag(countryCode: any) {
        return `https://assets.credible.finance/flags/24x24/${countryCode.toLowerCase()}.png`;
    }

    getUserTasks() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getUserTasks`, httpOptions);
    }

    getSpaceshipFaqQuestions() {
        return this.http.get<any>(`${serverUrl}getSpaceshipFaqQuestions`);
    }

    redirectUrl(component_name: string, url: string, extras?: NavigationExtras) {
        console.log('redirectUrl', component_name, url, extras);

        const indexOf = url.indexOf('?');

        if (indexOf === -1)
            this.router.navigate([ url ], extras);

        else {
            const base_url = url.slice(0, indexOf);

            console.log('base_url', base_url);

            const parsed = queryString.parse(url.slice(indexOf + 1));

            console.log('parsed', parsed);

            if (extras) {
                if (!extras.queryParams)
                    extras.queryParams = {};
            }

            else {
                extras = {
                    queryParams: {}
                };
            }

            extras.queryParams = {
                ...extras.queryParams,
                ...parsed
            };

            this.router.navigate([ base_url ], extras);
        }
    }

    getCurrencyList(type: string) {
        return this.http.get<any>(`${serverUrl}getListOfCurrencies?type=${type}`);
    }

    getWalletAddress(blockchain: string, currency: string) {
        const queryString = new URLSearchParams({
            blockchain,
            currency
        }).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getWalletAddress?${queryString}`, httpOptions);
    }
    getDeposits(body: any) {
        const queryString = new URLSearchParams(body).toString();
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getDepositHistory?${queryString}`, httpOptions);
    }

    getWithdrawalHistory(body: any) {
        const queryString = new URLSearchParams(body).toString();
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getWithdrawalHistory?${queryString}`, httpOptions);
    }

    getCurrencyBalanceV3(currency: string, mint_address: string | undefined) {
        const data: any = {
            currency
        };

        // if (!nullChecker(wallet_address))
        //     data.wallet_address = wallet_address;

        if (mint_address)
            data.mint_address = mint_address;

        const queryString = new URLSearchParams(data).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}getCurrencyBalanceV3?${queryString}`, httpOptions);
    }
    initiateWithdrawalRequest(body: object) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}initiateWithdrawalRequest`, body, httpOptions);
    }

    resendWithdrawalOtp(amount: any, currency: any) {
        const data: any = {
            currency,
            amount
        };
        const queryString = new URLSearchParams(data).toString();

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}resendWithdrawalOtp?${queryString}`, httpOptions);
    }

    getCredCurrencyDisplayName(currency: string) {
        return currency === 'cred' || currency === 'CRED' ? 'vCRED' : currency.toUpperCase();
    }

    getMembersGenesis(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(
            `${serverUrl}getMembersVesting?page=${data.page}&limit=${data.limit}&level=${data.level}`,
            httpOptions
        );
    }

    getOnboardingStats(level: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getOnboardingStats?level=${level}`, httpOptions);
    }

    getOnboardingChart(level: string, duration: string, type: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getOnboardingChart?level=${level}&duration=${duration}&type=${type}`, httpOptions);
    }

    getReferralLevels() {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.get<any>(`${serverUrl}ambassador/getReferralLevels`, httpOptions);
    }
}