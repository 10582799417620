import {
    Component, OnInit
} from '@angular/core';
import {
    ActivatedRoute, Router
} from '@angular/router';
import TabOption from '@crediblefinance/credible-ui/lib/cf-tabs/tab-option.interface';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrl: './transactions.component.scss'
})
export class TransactionsComponent implements OnInit {
    currentTab = 'my-supply';

    tabs: Array<TabOption> = [{
        label: 'Supply',
        value: 'my-supply'
    }, {
        label: 'Earnings',
        value: 'my-earnings'
    }, {
        label: 'Withdrawals',
        value: 'my-withdrawals'
    }, {
        label: 'Drawdowns',
        value: 'my-drawdowns'
    }, {
        label: 'Repayments',
        value: 'my-repayments'
    },
    {
        label: 'Cash Loan Drawdowns',
        value: 'my-cash-loan-drawdowns'
    }, {
        label: 'Cash Loan Repayments',
        value: 'my-cash-loan-repayments'
    // },
    // {
    //     label: 'Nodes',
    //     value: 'my-nodes'
    // }, 
    // {
    //     label: 'Unstakings',
    //     value: 'my-unstakings'
    // }, {
    //     label: 'Staking reward claiming',
    //     value: 'my-staking-reward-claiming'
    // }, {
    //     label: 'Referral reward claiming',
    //     value: 'my-referral-reward-claiming'
    }];

    constructor(private route: ActivatedRoute, private router: Router, public httpService: HttpService) {

    }

    ngOnInit(): void {
        this.currentTab = this.tabs[0].value;

        this.subscribeQueryParamsObservables();
    }

    subscribeQueryParamsObservables() {
        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.value === tab))
                    this.currentTab = tab;
            }
        });
    }

    changeTab(event: any) {
        this.currentTab = event.value;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
