// console.log('app.module.ts start');

import {
    LOCALE_ID, NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import {
    FormsModule, ReactiveFormsModule
} from '@angular/forms';
import {
    MatDialogModule, MatDialogRef
} from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {
    DatePipe, DecimalPipe
} from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NewInvestmentComponent } from './new-investment/new-investment.component';
import { ClaimLoyaltyComponent } from './claim-loyalty/claim-loyalty.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewWithdrawalComponent } from './new-withdrawal/new-withdrawal.component';
import { BorrowPoolDetailsComponent } from './borrow-pool-details/borrow-pool-details.component';
import { NewRepaymentComponent } from './new-repayment/new-repayment.component';
import { SocialCallbackComponent } from './social-callback/social-callback.component';
import { LendComponent } from './lend/lend.component';
import { LendWidgetComponent } from './lend-widget/lend-widget.component';
import ButtonMenuComponent from './button-menu/button-menu.component';
import { PoolActivityComponent } from './pool-activity/pool-activity.component';
import { LendPoolTransactionsComponent } from './lend-pool-transactions/lend-pool-transactions.component';
import { LendPoolWithdrawalsComponent } from './lend-pool-withdrawals/lend-pool-withdrawals.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BorrowWidgetComponent } from './borrow-widget/borrow-widget.component';
import { BorrowPoolLoansComponent } from './borrow-pool-loans/borrow-pool-loans.component';
import { BorrowPoolRepaymentsComponent } from './borrow-pool-repayments/borrow-pool-repayments.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { HomepageComponent } from './homepage/homepage.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { MyRepaymentsComponent } from './transactions/my-repayments/my-repayments.component';
import { MyWithdrawalsComponent } from './transactions/my-withdrawals/my-withdrawals.component';
import { NewDrawdownComponent } from './new-drawdown/new-drawdown.component';
import { MyEarningsComponent } from './transactions/my-earnings/my-earnings.component';
import { MyInvestmentsComponent } from './transactions/my-investments/my-investments.component';
import { BorrowerDashboardComponent } from './borrower-dashboard/borrower-dashboard.component';
import { NewEarningComponent } from './new-earning/new-earning.component';
import { MyDrawdownsComponent } from './transactions/my-drawdowns/my-drawdowns.component';
import { RegisterStepsComponent } from './register-steps/register-steps.component';
import { UserProfileComponent } from './register-steps/user-profile/user-profile.component';
import { VaultAllocationComponent } from './vault-allocation/vault-allocation.component';
import { InviteCodeComponent } from './register-steps/invite-code/invite-code.component';
import { AcceptInviteCodeComponent } from './accept-invite-code/accept-invite-code.component';
import { ChooseProfilePictureComponent } from './register-steps/choose-profile-picture/choose-profile-picture.component';
import { CfOtpComponent } from './cfOtp/cf-otp.component';
import { LendDefaultComponent } from './lend/lend-default/lend-default.component';
import { LendPoolDetailsComponent } from './lend-pool-details/lend-pool-details.component';
import { LendPoolDetailsDefaultComponent } from './lend-pool-details/lend-pool-details-default/lend-pool-details-default.component';
import { LendPoolDetailsVaultComponent } from './lend-pool-details/lend-pool-details-vault/lend-pool-details-vault.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { BorrowPoolDetailsDefaultComponent } from './borrow-pool-details/borrow-pool-details-default/borrow-pool-details-default.component';
import { BorrowPoolDetailsVaultComponent } from './borrow-pool-details/borrow-pool-details-vault/borrow-pool-details-vault.component';
import { MarqueeCarouselComponent } from './marquee-carousel/marquee-carousel.component';
import { AmbassadorOnboardingComponent } from './ambassador/ambassador-onboarding/ambassador-onboarding.component';
import { AmbassadorMoonsCreditComponent } from './ambassador/ambassador-moons-credit/ambassador-moons-credit.component';
import { AmbassadorTvlComponent } from './ambassador/ambassador-tvl/ambassador-tvl.component';
import { AmbassadorComponent } from './ambassador/ambassador.component';
import { AmbassadorCredComponent } from './ambassador/ambassador-cred/ambassador-cred.component';
import { MembersComponent } from './ambassador/members/members.component';
import { AmbassadorLeaderboardComponent } from './ambassador/ambassador-leaderboard/ambassador-leaderboard.component';
import { QRCodeModule } from 'angularx-qrcode';

import circleComponents from './circle.module';
import cfComponents from './cf.module';
import { HeaderPopupMenuComponent } from './header-popup-menu/header-popup-menu.component';
import { ChangeBlockchainDialogComponent } from './change-blockchain-dialog/change-blockchain-dialog.component';
import { ChangeChainDialogComponent } from './change-chain-dialog/change-chain-dialog.component';
import { LendPoolDetailsPayFiComponent } from './lend-pool-details/lend-pool-details-payfi/lend-pool-details-payfi.component';
import { PayFiWidgetComponent } from './payfi-widget/payfi-widget.component';
import { NewPayFiDrawdownComponent } from './new-payfi-drawdown/new-payfi-drawdown.component';
import { NewPayFiRepaymentComponent } from './new-payfi-repayment/new-payfi-repayment.component';
import { ProfileComponent } from './profile/profile.component';
import { MyPayFiDrawdownsComponent } from './transactions/my-payfi-drawdowns/my-payfi-drawdowns.component';
import { PayFiComponent } from './payfi/payfi.component';
import { PersonalProfileComponent } from './personal-profile/personal-profile.component';
import { MyPayFiRepaymentsComponent } from './transactions/my-payfi-repayments/my-payfi-repayments.component';
import { NewPayFiComponent } from './new-payfi/new-payfi.component';
import { MyBorrowingsPayFiComponent } from './my-borrowings-payfi/my-borrowings-payfi.component';
import { MyInvestmentsPayFiComponent } from './my-investments-payfi/my-investments-payfi.component';
import { PoolActivityPayFiComponent } from './pool-activity-payfi/pool-activity-payfi.component';
import { FaqPayFiComponent } from './faq-payfi/faq-payfi.component';
import { SpaceshipComponent } from './spaceship/spaceship.component';
import { NewPayFiCollateralComponent } from './new-payfi-collateral/new-payfi-collateral.component';
import { MyPayFiCollateralsComponent } from './transactions/my-payfi-collaterals/my-payfi-collaterals.component';
import { NewWithdrawalInvestmentComponent } from './new-withdrawal-investment/new-withdrawal-investment.component';
import { PayoutBeneficiariesComponent } from './payout-beneficiaries/payout-beneficiaries.component';
import { AddPayoutBeneficiaryComponent } from './add-payout-beneficiary/add-payout-beneficiary.component';
import { NewPayfiInvestmentComponent } from './new-payfi-investment/new-payfi-investment.component';
import { NewPayfiWithdrawalComponent } from './new-payfi-withdrawal/new-payfi-withdrawal.component';
import { NewPayfiWithdrawalInvestmentComponent } from './new-payfi-withdrawal-investment/new-payfi-withdrawal-investment.component';
import { NewPayFiEarningComponent } from './new-payfi-earning/new-payfi-earning.component';
import { LoginComponent } from './login/login.component';
import { LoginWithEmailComponent } from './login-with-email/login-with-email.component';
import { LoginWithWalletComponent } from './login-with-wallet/login-with-wallet.component';
import { EmailVerificationComponent } from './register-steps/email-verification/email-verification.component';
import { LoginConnectWalletComponent } from './login-with-wallet/login-connect-wallet/login-connect-wallet.component';
import { ConnectWalletComponent } from './dialogs/connect-wallet/connect-wallet.component';
import { NodeSaleComponent } from './node-sale/node-sale.component';
import { NodeWidgetComponent } from './node-sale/node-widget/node-widget.component';
import { NodePurchaseComponent } from './node-sale/node-purchase/node-purchase.component';
import { NodeRewardsComponent } from './node-sale/node-rewards/node-rewards.component';
// import { MyNodesComponent } from './node-sale/my-nodes/my-nodes.component';
import { MyPayfiInvestmentsComponent } from './transactions/my-payfi-investments/my-payfi-investments.component';
import { MyPayfiWithdrawalsComponent } from './transactions/my-payfi-withdrawals/my-payfi-withdrawals.component';
import { NewCardPurchaseComponent } from './new-card-purchase/new-card-purchase.component';
import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { NewNodeComponent } from './new-node/new-node.component';
import { SaleAgreementComponent } from './sale-agreement/sale-agreement.component';
import { PlanLoyaltyScheduleComponent } from './plan-loyalty-schedule/plan-loyalty-schedule.component';
import { PlanUnlockScheduleComponent } from './plan-unlock-schedule/plan-unlock-schedule.component';
import { MyNodesComponent } from './my-nodes/my-nodes.component';
import { MyNodeDetailsComponent } from './my-node-details/my-node-details.component';
import { PurchaseNodeWidgetComponent } from './purchase-node-widget/purchase-node-widget.component';
import { MyUnstakingsComponent } from './my-unstakings/my-unstakings.component';
import { NodeUnlockScheduleComponent } from './node-unlock-schedule/node-unlock-schedule.component';
import { NodeLoyaltyScheduleComponent } from './node-loyalty-schedule/node-loyalty-schedule.component';
import { NewStakingClaimComponent } from './new-staking-claim/new-staking-claim.component';
import { NewUnstakingComponent } from './new-unstaking/new-unstaking.component';
import { NetworkComponent } from './network/network.component';
import { GenesisMembersComponent } from './network/members/members.component';
import { GenesisMoonsComponent } from './network/moons/moons.component';
import { RewardsClaimHistoryComponent } from './network/rewards-claim-history/rewards-claim-history.component';
import { OnboardingComponent } from './network/onboarding/onboarding.component';
import { LeaderboardComponent } from './network/leaderboard/leaderboard.component';
import { LiquidityComponent } from './network/liquidity/liquidity.component';
import { MyReferralRewardsComponent } from './my-referral-rewards/my-referral-rewards.component';
import { ReferralRewardWithdrawalComponent } from './referral-reward-withdrawal/referral-reward-withdrawal.component';
import { MyNodesTransactionsComponent } from './transactions/my-nodes/my-nodes.component';
import { StakingRewardsClaimHistoryComponent } from './transactions/staking-rewards-claim-history/staking-rewards-claim-history.component';
import { InviteCodeModalComponent } from './invite-code-modal/invite-code-modal.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatStepperModule,
        AppRoutingModule,
        MatExpansionModule,
        QRCodeModule,
        ...cfComponents

    ],
    declarations: [
        AppComponent,
        CfOtpComponent,
        UserProfileComponent,
        FooterComponent,
        SidebarComponent,
        HeaderComponent,
        LoginConnectWalletComponent,
        DashboardComponent,
        NewInvestmentComponent,
        NewEarningComponent,
        NewWithdrawalComponent,
        ClaimLoyaltyComponent,
        MyInvestmentsComponent,
        MyRepaymentsComponent,
        BorrowPoolDetailsComponent,
        NewDrawdownComponent,
        MyEarningsComponent,
        MyWithdrawalsComponent,
        MyDrawdownsComponent,
        MyPayFiDrawdownsComponent,
        MyPayFiRepaymentsComponent,
        MyPayFiCollateralsComponent,
        NewRepaymentComponent,
        SocialCallbackComponent,
        LendComponent,
        LendPoolDetailsComponent,
        LendWidgetComponent,
        BorrowWidgetComponent,
        ButtonMenuComponent,
        PoolActivityComponent,
        VaultAllocationComponent,
        LendPoolTransactionsComponent,
        LendPoolWithdrawalsComponent,
        NotFoundComponent,
        HomepageComponent,
        BorrowPoolLoansComponent,
        BorrowPoolRepaymentsComponent,
        LineChartComponent,
        TransactionsComponent,
        BorrowerDashboardComponent,
        RegisterStepsComponent,
        InviteCodeComponent,
        MembersComponent,
        ChooseProfilePictureComponent,
        AcceptInviteCodeComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        HeaderPopupMenuComponent,
        LendDefaultComponent,
        LendPoolDetailsDefaultComponent,
        LendPoolDetailsPayFiComponent,
        LendPoolDetailsVaultComponent,
        VaultAllocationComponent,
        EmailVerificationComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        BorrowPoolDetailsDefaultComponent,
        BorrowPoolDetailsVaultComponent,
        MarqueeCarouselComponent,
        LoginComponent,
        LoginWithEmailComponent,
        LoginWithWalletComponent,
        AmbassadorComponent,
        AmbassadorOnboardingComponent,
        AmbassadorMoonsCreditComponent,
        AmbassadorTvlComponent,
        AmbassadorLeaderboardComponent,
        AmbassadorCredComponent,
        ChangeBlockchainDialogComponent,
        ChangeChainDialogComponent,
        PayFiWidgetComponent,
        NewPayFiDrawdownComponent,
        NewPayFiRepaymentComponent,
        NewPayFiCollateralComponent,
        ProfileComponent,
        PayoutBeneficiariesComponent,
        AddPayoutBeneficiaryComponent,
        ConnectWalletComponent,
        PayFiComponent,
        PersonalProfileComponent,
        NewPayFiComponent,
        MyBorrowingsPayFiComponent,
        MyInvestmentsPayFiComponent,
        PoolActivityPayFiComponent,
        FaqPayFiComponent,
        ...circleComponents,
        SpaceshipComponent,
        NewWithdrawalInvestmentComponent,
        NewPayfiInvestmentComponent,
        NewPayfiWithdrawalComponent,
        NewPayfiWithdrawalInvestmentComponent,
        NewPayFiEarningComponent,
        NodeSaleComponent,
        NodeWidgetComponent,
        NodePurchaseComponent,
        NodeRewardsComponent,
        MyPayfiInvestmentsComponent,
        MyPayfiWithdrawalsComponent,
        NewCardPurchaseComponent,
        FrequentlyAskedQuestionsComponent,
        PlanDetailsComponent,
        NewNodeComponent,
        SaleAgreementComponent,
        PlanLoyaltyScheduleComponent,
        PlanUnlockScheduleComponent,
        MyNodesComponent,
        MyNodeDetailsComponent,
        PurchaseNodeWidgetComponent,
        MyUnstakingsComponent,
        NodeUnlockScheduleComponent,
        NodeLoyaltyScheduleComponent,
        NewStakingClaimComponent,
        NewUnstakingComponent,
        NetworkComponent,
        GenesisMembersComponent,
        GenesisMoonsComponent,
        RewardsClaimHistoryComponent,
        OnboardingComponent,
        LeaderboardComponent,
        LiquidityComponent,
        MyReferralRewardsComponent,
        ReferralRewardWithdrawalComponent,
        MyNodesTransactionsComponent,
        StakingRewardsClaimHistoryComponent,
        InviteCodeModalComponent
    ],
    exports: [ ButtonMenuComponent ],
    providers: [
        DecimalPipe,
        DatePipe,
        {
            provide: MatDialogRef,
            useValue: {}
        },
        {
            provide: LOCALE_ID,
            useValue: 'en-IN'
        },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: {
                displayDefaultIndicatorType: false
            }
        },
        // provideClientHydration(),
        provideHttpClient()
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
