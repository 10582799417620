<div class="app-referral-reward-withdrawal" [ngClass]="httpService.theme">
    <div class="modal-backdrop" *ngIf="showModal">
        <div class="modal-container" *ngIf="showModal">
            <div class="referral-container">
                <div *ngIf="
            !loading &&
            httpService.user.user_id > 0 &&
            !show_no_purchases
        ">
                    <div class="heading-container">
                        <span class="title">Claim Rewards</span>
                        <i class="fa-solid fa-xmark ml icon" (click)="closeModal()" style="color: white;"></i>
                    </div>

                    <div class="row mt-2">
                        <div class="summary">
                            <div class="summary-item">
                                <span class="summary-heading">Available Balance</span>
                                <span class="summary-value">{{
                                    balance
                                    | number
                                    : httpService.getPrecision(currency)
                                    }}
                                    {{ currency | uppercase }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="actions">
                        <div *ngIf="!btn_loading">
                            <div *ngIf="differentBlockchain">
                                <cf-button label="Switch Blockchain" type="solid"
                                    (clicked)="openChangeBlockchainDialog()"></cf-button>
                            </div>

                            <div class="action" *ngIf="!differentBlockchain && walletService.wallet_address !== ''">
                                <cf-button label="Claim" type="solid" (clicked)="claimReferralReward()"
                                    *ngIf="showConfirmButton" [loading]="btn_loading"></cf-button>

                                <cf-button label="Switch Network" type="solid" (clicked)="openChangeChainDialog()"
                                    *ngIf="differentNetwork"></cf-button>

                            </div>
                            <div class="action" *ngIf="walletService.wallet_address === ''">
                                <cf-button label="Connect Wallet" type="solid" (clicked)="connectWallet()"></cf-button>
                            </div>
                        </div>
                        <div *ngIf="btn_loading">
                            <cf-loader></cf-loader>
                        </div>
                    </div>
                </div>
                <div class="loader" *ngIf="loading">
                    <cf-loader></cf-loader>
                </div>
                <div *ngIf="show_no_purchases" class="no-data">
                    <div>No purchases</div>
                </div>
            </div>
        </div>
    </div>