<div class="app-network container-fluid">
    <cf-tabs [theme]="httpService.theme" [tabs]="tabs" [currentTab]="currentTab"
        (changed)="changeTab($event)"></cf-tabs>

    <div class="tabs">
        <app-genesis-members *ngIf="currentTab === 'members'"></app-genesis-members>
        <app-my-referral-rewards *ngIf="currentTab === 'networking-rewards'"></app-my-referral-rewards>
        <app-rewards-claim-history *ngIf="currentTab === 'rewards-claim-history'"></app-rewards-claim-history>
        <app-onboarding *ngIf="currentTab === 'onboarding'"></app-onboarding>
        <app-leaderboard *ngIf="currentTab === 'leaderboard'"></app-leaderboard>
        <app-liquidity *ngIf="currentTab === 'liquidity'"></app-liquidity>
        <app-genesis-moons *ngIf="currentTab === 'moons'"></app-genesis-moons>
    </div>
</div>