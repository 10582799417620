import { Injectable } from '@angular/core';
import { PhantomProvider } from '../interfaces/PhantomProvider';
import { getWindow } from 'ssr-window';
import {
    PublicKey, Transaction
} from '@solana/web3.js';
import { WalletService } from './wallet.service';

@Injectable({
    providedIn: 'root'
})
export class PhantomService {
    window: Window;
    provider: PhantomProvider | undefined;
    wallet_address: string = '';

    constructor(private walletService: WalletService) {
        this.window = getWindow();
        this.setProvider();
        this.attachProviderEvents();
    }

    isPresent() {
        return 'phantom' in this.window;
    }

    setProvider() {
        if ('phantom' in this.window) {
            const phantom = this.window.phantom as any;
            const provider = phantom ? phantom.solana : null;

            if (provider?.isPhantom)
                this.provider = provider;
        }
    }

    async connectWallet() {
        try {
            await this.provider?.connect({});
        }
        catch (err) {
            console.error(err);
        }
    }

    disconnectWallet() {
        this.provider?.disconnect();

        this.walletService.current_provider = '';
        this.wallet_address = '';
        this.walletService.wallet_address = this.wallet_address;
        this.walletService.blockchain = '';
    }

    async signMessageLedger(publicKey: PublicKey) {
        const tx = this.walletService.buildAuthTx(this.walletService.messageForSigning);

        tx.feePayer = publicKey; // not sure if needed but set this properly
        tx.recentBlockhash = await this.walletService.getLatestBlockhash();

        // Encode and send tx to signer, decode and sign
        const signedTx = await this.provider?.signTransaction(tx);

        console.log('signedTx', signedTx);
        
        if (!signedTx) {
            this.walletService.setEvent('phantom', 'signMessage', {
                status: 'failure'
            });

            return;
        }

        const serializedTx = signedTx.serialize();

        const validSignature = this.walletService.validateAuthTx(signedTx, this.walletService.messageForSigning);

        console.log('validSignature', validSignature);

        if (!validSignature) {
            this.walletService.setEvent('phantom', 'signMessage', {
                status: 'failure'
            });
        }

        else {
            console.log('signature', signedTx?.signature);

            this.walletService.wallet_address = this.wallet_address;

            this.walletService.setEvent('phantom', 'signMessage', {
                status: 'success',
                serializedTx: serializedTx
            });
        }
    }

    async signMessageNonLedger() {
        console.log('signMessageNonLedger');

        const encodedMessage = new TextEncoder().encode(this.walletService.messageForSigning);
        const signedMessage = await this.provider?.signMessage(encodedMessage, 'utf8');

        console.log('signedMessage', signedMessage);

        if (!signedMessage || !signedMessage.signature) {
            this.walletService.setEvent('phantom', 'signMessage', {
                status: 'failure'
            });
        }

        else {
            this.walletService.setEvent('phantom', 'signMessage', {
                status: 'success',
                signature: signedMessage.signature
            });
        }
    }

    async signMessage(publicKey: PublicKey) {
        console.log('signMessage', this.walletService.ledger);

        if (this.walletService.ledger)
            await this.signMessageLedger(publicKey);

        else
            await this.signMessageNonLedger();
    }

    attachProviderEvents() {
        this.provider?.on('connect', async (publicKey: PublicKey) => {
            const address = publicKey.toBase58();

            console.log('attachProviderEvents phantom connect', address);
            console.log('attachProviderEvents this.provider', this.provider);

            this.walletService.current_provider = 'phantom';
            this.wallet_address = address;
            this.walletService.wallet_address = this.wallet_address;
            this.walletService.blockchain = 'solana';

            this.walletService.setEvent('phantom', 'connect', {
                wallet_address: this.wallet_address
            });

            await this.signMessage(publicKey);
        });

        this.provider?.on('disconnect', () => {
            console.log('Phantomservice disconnected');

            this.disconnectWallet();

            console.log('wallet_address', this.wallet_address);

            this.walletService.setEvent('phantom', 'disconnect', {
                wallet_address: this.wallet_address
            });
        });

        this.provider?.on('accountChanged', (publicKey: PublicKey) => {
            if (publicKey) {
                // Set new public key and continue as usual
                console.log(`Switched to account + + + *${this.wallet_address, publicKey.toBase58()}`);

                this.walletService.setEvent('phantom', 'disconnect', {
                    old_address: this.wallet_address,
                    new_address: publicKey.toBase58()
                });
            }
            else {
                // Attempt to reconnect to Phantom
                this.provider?.connect().catch((error: Error) => {
                    console.error(error);
                });
            }
        });
    }

    signAndSendTransaction(transaction: Transaction) {
        return this.provider?.signAndSendTransaction(transaction, {
            skipPreflight: true,
            maxRetries: 0
        });
    }

    signTransaction(transaction: Transaction) {
        return this.provider?.signTransaction(transaction);
    }

    downloadWallet() {
        this.window.open('https://phantom.app/', '_blank');
    }

    connectWalletMobile() {
        const callbackUrl = 'https://credible.finance';

        const deepLink = `https://phantom.app/ul/browse/${callbackUrl}?ref=${callbackUrl}`;

        console.log('RELOAD ISSUE URL : HeaderComponent deepLink => ', this.window.location.href);

        return this.window.location.href = deepLink;
    }
}
