import { KycStatusEnum } from '../enums/KycStatusEnum';
import IUser from '../interfaces/IUser';

export default class User implements IUser {
    account_type: string = '';
    first_name = '';
    email_verified = false;
    middle_name = '';
    last_name = '';
    created = 0;
    companyName: string = '';
    residence_country: string = '';
    nationality_country: string = '';
    residence_country_code: string = '';
    nationality_country_code: string = '';
    kyc_status: KycStatusEnum = KycStatusEnum.Pending;
    email = '';
    enabled = true;
    user_id = 0;
    referral_link: string = '';
    referral_code: number = 0;
    referred_by: number = 0;
    display_name: string = '';
    profile_picture: string = '';
    terms_and_conditions: boolean = false;
    diamonds: number = 0;
    ambassador = false;
    blockchain = 'solana';
    moons: number = 0;
    show_funds:boolean = false;
    invite_code_step: boolean = false;
}
