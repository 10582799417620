import INode from '../interfaces/INode';

export default class Node implements INode {
    name: string = '';
    blockchain: string = '';
    chain: string = '';
    created: number = 0;
    chain_id: number = 0;
    cred_receivable: number = 0;
    cred_receivable_usd: number = 0;
    cred_unlocked: number = 0;
    cred_locked: number = 0;
    current_node_value: number = 0;
    percentage_change: number = 0;
    current_node_value_change_percentage: string = '';
    description: string = '';
    discount_rate: number = 0;
    enabled: Boolean = false;
    network_image_url: string = '';
    plan_id: string = '';
    node_type: string = '';
    yield_rate: number = 0;
    payment_currency: string = '';
    payment_currency_mint_address: string = '';
    staking_duration: number = 0;
    loyalty_duration: number = 0;
    cred_rate_payment_currency: number = 0;
    updated: number = 0;
    network_joining_fees: number = 0;
    vesting_period: number = 0;
    rate_chart: any;
    staking_info: any;
    delegate_user_info: any;
    staking_reward_info: any;
    node_id: string = '';
    blockchain_txid: string = '';
    payment_amount: number = 0;
    status = '';
    program_id: string = '';
    card_based_unlock_percentage: number = 0;
    reward_weight_percentage: number = 0;
    implied_fdv: number = 0;
    network: string = '';
}